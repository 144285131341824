import React from 'react';
import DrcIconButton from './DrcIconButton';
import DrcButton from './DrcButton';
import DrcFilterItem from './DrcFilterItem';
import FilterListIcon from '@material-ui/icons/FilterList';

class DrcFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterOpen: false,
            filtersHeight: 100
        };

        this.onFilterBtnClick = this.onFilterBtnClick.bind(this);
        this.onFilterSearch = this.onFilterSearch.bind(this);
        this.updateFiltersHeight = this.updateFiltersHeight.bind(this);
    }

    onFilterBtnClick() {
        this.setState({
            filterOpen: !this.state.filterOpen
        });
    }

    onFilterSearch() {
        if (this.props.onSearch) {
            this.props.onSearch();
        }

        this.setState({
            filterOpen: false
        });
    }

    updateFiltersHeight() {
        if (!this.filtersElement) {
            return;
        }

        const filtersHeight = this.filtersElement.clientHeight;

        if (filtersHeight != this.state.filtersHeight) {
            this.setState({ filtersHeight });
        }
    }

    componentDidMount() {
        this.updateFiltersHeight();
    }

    componentDidUpdate() {
        this.updateFiltersHeight();
    }

    render() {
        const { children, filters, onRemove, tagDivStyle, filterDivStyle, hideSearchBtn } = this.props;

        return (
            <div>
                <div style={tagDivStyle}>
                    {filters
                        .filter((filter) => filter.label && filter.label.length > 0)
                        .map((filter) => (
                            <DrcFilterItem key={filter.type} name={filter.name} type={filter.type} label={filter.label} onRemove={onRemove} />
                        ))}
                </div>
                <hr style={{ marginTop: '30px' }} />
                <DrcIconButton onClick={this.onFilterBtnClick}>
                    <FilterListIcon style={{ height: '24px' }} />
                </DrcIconButton>
                <div
                    style={{
                        maxHeight: this.state.filterOpen ? this.state.filtersHeight + 'px' : '0',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s linear',
                        marginTop: '-28px'
                    }}
                >
                    <div ref={(divElement) => (this.filtersElement = divElement)} style={{ padding: '15px 0 10px' }}>
                        <div className="row" style={filterDivStyle}>
                            {children}
                        </div>
                        {hideSearchBtn ? null : (
                            <DrcButton isPrimary onClick={this.onFilterSearch} style={{ marginLeft: 0, marginTop: 0 }}>
                                Search
                            </DrcButton>
                        )}
                    </div>
                </div>
                <hr style={{ marginTop: 0 }} />
                <br />
            </div>
        );
    }
}

export default DrcFilter;
