import DuLogger from './DuLogger';

const CACHE_CONFIG_KEY = 'cacheSettings';
const SESSION_KEY = '_session';
const LOG_KEY = 'DU_CACHE:: ';

var configLoaded = false;
/**
 *@ignore
 */
var cacheSettings = {
    lifespanInMilliseconds: 3600000,
    cacheVersion: process.env.REACT_APP_VERSION,
    cacheKeys: []
};
/**
 *@ignore
 */
const defaultCacheFunc = async () => {
    DuLogger.error(`${LOG_KEY}Not Setup!!!`);
};

let cacheGlobal = !!window ? window : global;

/**
 * @description {*} function that set's key data in local storage.
 * @param {*} Key
 * @param {*} Value
 *
 */
let setPermanentMethod =
    cacheGlobal && cacheGlobal.localStorage
        ? async (key, value) => {
              cacheGlobal.localStorage.setItem(key, value);
          }
        : defaultCacheFunc;

/**
 * @description {*} function that get's  key data from local storage.
 * @param {*} Key
 * @return {Object} Key's data
 */
let readPermanentMethod =
    cacheGlobal && cacheGlobal.localStorage
        ? async (key) => {
              return new Promise(async (resolve) => {
                  resolve(cacheGlobal.localStorage.getItem(key));
              });
          }
        : defaultCacheFunc;

/**
 * @description {*} function that removes  key data in local storage.
 * @param {*} Key
 *
 */
let deletePermanentMethod =
    cacheGlobal && cacheGlobal.localStorage
        ? async (key) => {
              cacheGlobal.localStorage.removeItem(key);
          }
        : defaultCacheFunc;
/**
 * @description {*} function that set's  key data in session storage.
 * @param {*} Key
 *
 */
let setTemporaryMethod =
    cacheGlobal && cacheGlobal.sessionStorage
        ? async (key, value) => {
              cacheGlobal.sessionStorage.setItem(key, value);
          }
        : defaultCacheFunc;
/**
 * @description {*} function that get's  key data from session storage.
 * @param {*} Key
 * @return {Object} Key's data
 */
let readTemporaryMethod =
    cacheGlobal && cacheGlobal.sessionStorage
        ? async (key) => {
              return new Promise(async (resolve) => {
                  resolve(cacheGlobal.sessionStorage.getItem(key));
              });
          }
        : defaultCacheFunc;
/**
 * @description {*} function that removes value from local storage.
 * @param {*} Key
 *
 */
let deleteTemporaryMethod =
    cacheGlobal && cacheGlobal.sessionStorage
        ? async (key) => {
              cacheGlobal.sessionStorage.removeItem(key);
          }
        : defaultCacheFunc;

let cacheIsSetup = cacheGlobal && cacheGlobal.localStorage && cacheGlobal.sessionStorage;

/**
 * @description sets data in local/ session storgage. Logs in case of error.
 * @param {function} setPermanentFunc setter function for setting value in local storage.
 * @param {function} readPermanentFunc getter function for getting value from local storage.
 * @param {function} deletePermanentFunc function function to delete value form  local storage.
 * @param {function} setTemporaryFunc setter function for setting value in session storage.
 * @param {function} readTemporaryFunc  getter function for getting value from session storage.
 * @param {function} deleteTemporaryFunc function function to delete value form  session storage.
 * @returns {*}
 */
const setupCache = async (setPermanentFunc, readPermanentFunc, deletePermanentFunc, setTemporaryFunc, readTemporaryFunc, deleteTemporaryFunc) => {
    if (!setTemporaryFunc) {
        setTemporaryFunc = setPermanentFunc;
    }

    if (!readTemporaryFunc) {
        readTemporaryFunc = readPermanentFunc;
    }

    if (!deleteTemporaryFunc) {
        deleteTemporaryFunc = deletePermanentFunc;
    }

    if (
        !verifyFunction(setPermanentFunc) ||
        !verifyFunction(readPermanentFunc) ||
        !verifyFunction(deletePermanentFunc) ||
        !verifyFunction(setTemporaryFunc) ||
        !verifyFunction(readTemporaryFunc) ||
        !verifyFunction(deleteTemporaryFunc)
    ) {
        cacheIsSetup = false;
        DuLogger.error(`${LOG_KEY}Unable to find a required function for cache to function. No cache can be saved!`);
        return;
    }

    setPermanentMethod = setPermanentFunc;
    readPermanentMethod = readPermanentFunc;
    deletePermanentMethod = deletePermanentFunc;

    setTemporaryMethod = setTemporaryFunc;
    readTemporaryMethod = readTemporaryFunc;
    deleteTemporaryMethod = deleteTemporaryFunc;

    await configure();
};

/**
 * @description checks if input parameter is a function
 * @param {function} func
 * @returns {Boolean}
 */
const verifyFunction = async (func) => {
    if (typeof func !== 'function') {
        return false;
    }

    return true;
};

/**
 * @description Function to set cache config key and cache settings in local storage once cache is setup.
 * @method configure
 *
 */
const configure = async () => {
    if (!configLoaded && cacheIsSetup) {
        var cachedSettingsString = (await readPermanentMethod(CACHE_CONFIG_KEY)) || '';

        if (cachedSettingsString.length <= 0) {
            configLoaded = true;
            return;
        }

        var cachedSettings = JSON.parse(cachedSettingsString);

        if (cachedSettings) {
            cacheSettings = cachedSettings;

            if (process.env.REACT_APP_VERSION !== cacheSettings.cacheVersion) {
                clearAllCache();
                cacheSettings.cacheVersion = process.env.REACT_APP_VERSION;
            }
        } else {
            await setPermanentMethod(CACHE_CONFIG_KEY, JSON.stringify(cacheSettings));
        }

        configLoaded = true;
    }
};

/**
 * @description clears all data set in window local storage
 */
const clearAllCache = async () => {
    for (let key of cacheSettings.cacheKeys) {
        clearCache(key);
    }

    cacheSettings.cacheKeys = [];
    await setPermanentMethod(CACHE_CONFIG_KEY, JSON.stringify(cacheSettings));
};

/**
 * @description Adds session key to the Array of cache keys.
 * @method addKeyToIndex
 * @param {String} key
 * @param {Boolean} isPermanentStorage
 *
 */
const addKeyToIndex = async (key, isPermanentStorage) => {
    if (!isPermanentStorage) {
        key = key + SESSION_KEY;
    }

    if (cacheSettings.cacheKeys.includes(key)) {
        return;
    }

    cacheSettings.cacheKeys.push(key);

    await setPermanentMethod(CACHE_CONFIG_KEY, JSON.stringify(cacheSettings));
};

/**
 * @description set's key data in web storage
 * @method saveCache
 * @param {String} key
 * @param {Object} data
 * @param {boolean} isPermanentStorage
 *
 */
const saveCache = async (key, data, isPermanentStorage = true) => {
    await configure();

    if (!configLoaded) {
        DuLogger.error(`${LOG_KEY}Config not able to load, please fix the config!`);
        return false;
    }

    var cacheItem = {
        time: Date.now(),
        data: data
    };

    addKeyToIndex(key, isPermanentStorage);

    if (isPermanentStorage) {
        await setPermanentMethod(key, JSON.stringify(cacheItem));
    } else {
        await setTemporaryMethod(key, JSON.stringify(cacheItem));
    }

    return true;
};

/**
 * @description function to delete session key from local / session storage.
 * @method clearCache
 * @param {String} key
 *
 */
const clearCache = async (key) => {
    await configure();

    if (!configLoaded) {
        DuLogger.error(`${LOG_KEY}Config not able to load, please fix the config!`);
        return;
    }

    if (key.includes(SESSION_KEY)) {
        key = key.replace(SESSION_KEY, '');
        await deleteTemporaryMethod(key);
    } else {
        await deletePermanentMethod(key);
    }
};

/**
 * @description checks if cache is still valid within the given timespan
 * @method checkCache
 * @param {String} key
 * @param {Number} lifespanOverrideInMilliseconds current lifespan
 * @param {boolean} useGracePeriod adds more life span
 * @param {boolean} isPermanentStorage true by default
 * @returns {boolean}
 */
const checkCache = async (key, lifespanOverrideInMilliseconds, useGracePeriod, isPermanentStorage = true) => {
    await configure();

    if (!configLoaded) {
        DuLogger.error(`${LOG_KEY}Config not able to load, please fix the config!`);
        return false;
    }

    var cacheItem = isPermanentStorage ? await readPermanentMethod(key) : await readTemporaryMethod(key);

    if (!cacheItem) {
        return false;
    }

    cacheItem = JSON.parse(cacheItem);

    if (!cacheItem) {
        clearCache(key);

        return false;
    }

    var timeDifference = Date.now() - cacheItem.time;
    var lifespan = lifespanOverrideInMilliseconds > 0 ? lifespanOverrideInMilliseconds : cacheSettings.lifespanInMilliseconds;

    if (timeDifference > lifespan + (useGracePeriod ? 10000 : 0)) {
        clearCache(key);

        return false;
    }

    return true;
};

/**
 * @description get's cached data for the given key
 * @method getCache
 * @param {String} key
 * @param {Number} lifespanOverrideInMilliseconds
 * @param {boolean} isPermanentStorage set as default true
 * @returns {String} key data
 */
const getCache = async (key, lifespanOverrideInMilliseconds, isPermanentStorage = true) => {
    if (await checkCache(key, lifespanOverrideInMilliseconds, true, isPermanentStorage)) {
        return JSON.parse(isPermanentStorage ? await readPermanentMethod(key) : await readTemporaryMethod(key)).data;
    }

    return null;
};

/**
 *
 * @constant DuCache
 */
const DuCache = {
    Setup: setupCache,
    Save: saveCache,
    Clear: clearCache,
    Check: checkCache,
    Get: getCache
};

export default DuCache;
