import React from 'react';
import { withStyles } from '@material-ui/core/styles';

var environment = '';

const styles = (theme) => ({
    root: {
        maxWidth: '400px',
        margin: 'auto',
        border: '1px solid ' + theme.light.buttonBorder,
        marginTop: '-9px',
        padding: '10px',
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary
        }
    },
    text: {
        textAlign: 'right'
    }
});


/**
 *
 *
 * @class DrcFooter
 * @extends {React.Component}
 */
class DrcFooter extends React.Component {
    constructor(props) {
        super(props);

        var hostname = window.location.hostname.toLowerCase();

        if (hostname.includes('dev') || hostname.includes('localhost')) {
            environment = 'Development';
        } else if (hostname.includes('stage')) {
            environment = 'Stage';
        } else if (hostname.includes('uat')) {
            environment = 'UAT';
        } else if (hostname.includes('qa')) {
            environment = 'QA';
        } else if (hostname.includes('tst') || hostname.includes('test')) {
            environment = 'Test';
        } else {
            environment = 'Production';
        }
    }

    render() {
        return (
            <footer className={this.props.classes.root}>
                <div className="row">
                    <div className={`col-xs-6 ${this.props.classes.text}`}>Application Version:</div>
                    <div className="col-xs-6">{process.env.REACT_APP_VERSION}</div>
                </div>
                <div className="row">
                    <div className={`col-xs-6 ${this.props.classes.text}`}>Library Version:</div>
                    <div className="col-xs-6">{window.Driscolls_Controls_Version}</div>
                </div>
                <div className="row">
                    <div className={`col-xs-6 ${this.props.classes.text}`}>Environment:</div>
                    <div className="col-xs-6">{environment || 'N/A'}</div>
                </div>
            </footer>
        );
    }
}

export default withStyles(styles)(DrcFooter);
