import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcTooltip from './DrcTooltip';

/**
 *@ignore
 */
const styles = (theme) => ({
    tooltip: {
        fontSize: '1rem'
    }
});

/**
 * @description DrcGlossaryLabel is a customizable wrapper to display informative text when user hovers over a component (or) a group of components
 * @class DrcGlossaryLabel
 * @property {object} classes Sets the style of DrcGlossaryLabel
 * @property {string} description Sets the description
 * @property {*} children Child elements
 * @example
 * <DrcGlossaryLabel description={'Glossary description'}>
 *       Hover over the text or the button
 *       <DrcButton isPrimary size="small">
 *           Save
 *       </DrcButton>
 *   </DrcGlossaryLabel>
 * @extends {React.Component}
 */

class DrcGlossaryLabel extends React.Component {

    /**
     * @return {*} 
     * @memberof DrcGlossaryLabel
     */

    render() {
        const { classes, description, children } = this.props;
        return (
            <DrcTooltip tipText={description} className={classes.tooltip}>
                <span>{children}</span>
            </DrcTooltip>
        );
    }
}

export default withStyles(styles)(DrcGlossaryLabel);
