// props
// fileTypes: []. currently can include "csv" or "excel"
// onSubmit: callback function with args (fileName, data)
// enableMultiSheet: Bool. When validating excels, allows you to upload all sheets

import React from 'react';
import DrcPanel from './DrcPanel';
import DrcButton from './DrcButton';
import DrcFileUpload from './DrcFileUpload';
import { withStyles } from '@material-ui/core/styles';

const extensions = { excel: ['.xlsx', '.xls', '.xlsb'], csv: ['.csv'], pdf: ['.pdf'] };
const styles = (theme) => ({
    submitBtnContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    submitBtn: {
        width: 150,
        textAlign: 'center'
    }
});

class DrcManagedFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typesAllowed: [],
            ableToSubmit: false,
            data: null,
            fileName: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.buildAllowedTypes();
    }

    async buildAllowedTypes() {
        var fileTypes = this.props.fileTypes;

        if (fileTypes && this.state.typesAllowed.length < 1) {
            var typesAllowed = [];

            fileTypes.forEach((type) => {
                typesAllowed = typesAllowed.concat(extensions[type]);
            });
            await this.setState({ typesAllowed: [...typesAllowed] });
        }
    }

    handleFile = (file) => {
        this.setState({
            fileName: file.fileName,
            data: file.data,
            ableToSubmit: true
        });
    };

    removeDrop(removeUploadedFile) {
        this.removeUploadedFile = removeUploadedFile;
    }

    handleSubmit() {
        const state = this.state;
        if (!!this.props.onSubmit) {
            this.props.onSubmit(state.fileName, state.data);
        }
        this.removeUploadedFile();
        this.setState({
            fileName: '',
            data: null,
            ableToSubmit: false
        });
    }

    handleCancel() {
        this.removeUploadedFile();
        this.setState({
            fileName: '',
            data: null,
            ableToSubmit: false
        });
    }

    render() {
        const { classes, overrideFileTypes } = this.props;
        //this.buildAllowedTypes();
        return (
            <React.Fragment>
                <DrcPanel maxWidth={'100%'}>
                    <DrcFileUpload
                        parsed={this.handleFile}
                        removeUpload={this.removeDrop.bind(this)}
                        fileTypeOptions={overrideFileTypes || this.state.typesAllowed}
                        enableMultiSheet={this.props.enableMultiSheet}
                    />
                    <div className={`col-xs-12 ${classes.submitBtnContainer}`}>
                        <DrcButton
                            isPrimary
                            poly
                            noStyle
                            disabled={!this.state.ableToSubmit}
                            className={classes.submitBtn}
                            onClick={this.handleSubmit}
                            //to={this.getReviewLink()}
                        >
                            Submit
                        </DrcButton>
                        <DrcButton isPrimary poly noStyle disabled={!this.state.data} className={classes.submitBtn} onClick={this.handleCancel}>
                            Cancel
                        </DrcButton>
                    </div>
                </DrcPanel>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcManagedFileUpload);
