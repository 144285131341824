import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import DrcInput from './DrcInput';
import DrcButton from './DrcButton';
import { withStyles } from '@material-ui/core/styles';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { startOfWeek, endOfWeek } from 'date-fns';

/**
 * @ignore
 */
const styles = (theme) => ({
    root: {
        marginTop: '5px !important',
        marginBottom: '5px',
        '& .MuiButtonBase-root.MuiIconButton-root': {
            marginRight: '-15px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.buttonBorder + theme.important
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.accent.primary + theme.important
        },
        '& .MuiInputAdornment-root button': {
            color: theme.light.text.primary + theme.important
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.text.primary + theme.important
        },
        [theme.darkTheme]: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.buttonBorder + theme.important
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.dark.text.primary + theme.important
                }
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.accent.primary + theme.important
            },
            '& input.MuiInputBase-input': {
                color: theme.dark.text.primary + theme.important
            },
            '& .MuiInputAdornment-root button': {
                color: theme.dark.text.primary + theme.important
            }
        }
    },
    popup: {
        '@media (prefers-color-scheme: dark)': {
            filter: 'invert(1)',
            boxShadow: '0px -1px 45px -8px rgba(92,92,92,1)'
        },
        boxShadow: theme.light.shadow[0] + ', ' + theme.light.shadow[0],
        minHeight: 'max-content',
        background: 'white'
    },
    actionButtons: {
        margin: '5% !important'
    },
    labelError: {
        color: theme.light.text.errorTitle,
        fontSize: '1.25rem',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

/**
 * @description Array with months in a year
 * @type {*} */
var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * @description DrcDateRangePicker is a highly styled and controlled component for picking a range of dates. It comes with validations for common date selection use cases.
 * @class DrcDateRangePicker
 * @property {date} defaultValue Sets the default value
 * @property {date} minDate Sets a defined minimum date and disables earlier dates
 * @property {date} maxDate Sets a defined maximum date and disables later dates
 * @property {date} startDate Sets the start date
 * @property {date} endDate Sets the end date
 * @property {string} direction Orientation of calendar (eg: horizontal/vertical)
 * @property {boolean} weekDisplay To select a range of weeks
 * @example
 * <DrcDateRangePicker
        style={{ color: '#563e70' }}
        months={2}
        direction="horizontal"
        placeholder={'Select a date range'}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        weekDisplay={false}
        startDate={new Date()}
        endDate={new Date()}
        defaultValue={new Date()}
        label={'DrcDateRangePicker Example'}
        name="DrcDateRangePicker Example"
        color="#6f5091"
    />
 
 * @extends {React.Component}
 */
class DrcDateRangePicker extends React.Component {
    /**
     * Creates an instance of DrcDateRangePicker.
     * @param {*} props
     * @memberof DrcDateRangePicker
     */
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectionRange: {
                startDate: props.startDate === null || props.startDate ? props.startDate : new Date(),
                endDate: props.endDate === null || props.endDate ? props.endDate : null,
                color: props.color ? props.color : '#4a773c',
                key: props.key ? props.key : 'selection',
                autoFocus: props.autoFocus ? props.autoFocus : true,
                disabled: props.disabled ? props.disabled : false,
                showDateDisplay: props.showDateDisplay ? props.showDateDisplay : true
            }
        };
    }

    /**
     * @description Function to show date range picker
     * @param {*} e
     * @memberof DrcDateRangePicker
     */
    handleClick = (e) => {
        e.preventDefault();
        this.setState({ show: true });
        document.addEventListener('click', this.handleGlobalClick);
    };

    /**
     * @description Function to hide the date range picker when clicked outside the container
     * @param {*} e
     * @memberof DrcDateRangePicker
     */
    handleGlobalClick = (e) => {
        if (this.node && this.node.contains(e.target)) return;
        this.setState({ show: false });
        document.removeEventListener('click', this.handleGlobalClick);
    };

    /**
     * @description Function to handle change event of start and end dates
     * @param {*} ranges
     * @memberof DrcDateRangePicker
     */
    handleChange = (ranges) => {
        let newSelectionRange;
        if (!this.props.weekDisplay) {
            newSelectionRange = { ...this.state.selectionRange, startDate: ranges.selection.startDate, endDate: ranges.selection.endDate };
        } else {
            newSelectionRange = {
                ...this.state.selectionRange,
                startDate: startOfWeek(new Date(ranges.selection.startDate)),
                endDate: endOfWeek(new Date(ranges.selection.endDate))
            };
        }
        this.setState({ selectionRange: newSelectionRange });
        if (this.props.onChange) this.props.onChange(newSelectionRange.startDate, newSelectionRange.endDate);
    };

    /**
     * @description Function to reset date ranges
     * @memberof DrcDateRangePicker
     */
    handleReset = () => {
        let resetSelectionRange;
        if (!this.props.weekDisplay) {
            resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
            this.setState({ selectionRange: resetSelectionRange });
            if (this.props.onChange) this.props.onChange(resetSelectionRange.startDate, resetSelectionRange.endDate);
        } else {
            resetSelectionRange = { ...this.state.selectionRange, startDate: new Date(), endDate: new Date() };
            this.setState({ selectionRange: resetSelectionRange });
            if (this.props.onReset) this.props.onReset(resetSelectionRange.startDate, resetSelectionRange.endDate);
        }
    };

    /**
     * @description Function to save dates upon selection
     * @memberof DrcDateRangePicker
     */
    handleSaveBtnClick = () => {
        if (this.props.onSaveClicked) this.props.onSaveClicked();
        this.setState({ show: false });
    };

    /**
     * @description Function to format start and end dates
     * @param {date} startDate
     * @param {date} endDate
     * @param {*} formattedStart
     * @param {*} formattedEnd
     * @memberof DrcDateRangePicker
     */
    combineYearLabel = (startDate, endDate, formattedStart, formattedEnd) => {
        if (startDate === null || endDate === null) {
            return formattedStart + ':' + formattedEnd;
        }
        if (startDate.getFullYear() === endDate.getFullYear()) {
            return months[startDate.getMonth()] + ' ' + startDate.getDate() + ' : ' + months[endDate.getMonth()] + ' ' + endDate.getDate() + ', ' + startDate.getFullYear();
        }
    };

    /**
     * @return {*}
     * @memberof DrcDateRangePicker
     */
    render() {
        const {
            label,
            placeholder,
            variant,
            helperText,
            width,
            style,
            InputProps,
            InputLabelProps,
            classes,
            onClose,
            required,
            months,
            direction,
            isDateRange,
            combineLabelYears,
            weekDisplay,
            defaultValue,
            minDate,
            maxDate,
            startDate,
            endDate,
            ...other
        } = this.props;

        const todaysFormattedStartDate = this.state.selectionRange.startDate ? DuDateUtilities.ToString(this.state.selectionRange.startDate) : '';
        const todaysFormattedEndDate = this.state.selectionRange.endDate ? DuDateUtilities.ToString(this.state.selectionRange.endDate) : '';

        return (
            <div>
                <div>
                    <DrcInput
                        className={classes.root}
                        style={{ width: width || '100%', ...style }}
                        fullWidth={true}
                        margin="dense"
                        label={
                            required ? (
                                <span>
                                    {label || ''} <span className={classes.labelError}>*</span>
                                </span>
                            ) : (
                                label || ''
                            )
                        }
                        isDateRange={isDateRange !== undefined ? isDateRange : true}
                        placeholder={placeholder}
                        value={
                            !defaultValue
                                ? combineLabelYears
                                    ? this.combineYearLabel(
                                          this.state.selectionRange.startDate,
                                          this.state.selectionRange.endDate,
                                          todaysFormattedStartDate,
                                          todaysFormattedEndDate
                                      )
                                    : `${todaysFormattedStartDate} : ${todaysFormattedEndDate}`
                                : 'Default'
                        }
                        onClick={this.handleClick}
                        onClose={onClose}
                        InputProps={{
                            ...InputProps
                        }}
                        InputLabelProps={{
                            ...InputLabelProps
                        }}
                        helperText={helperText || ''}
                        error={(helperText || '').length > 0}
                        autoComplete="off"
                        {...other}
                    />
                </div>
                {this.state.show && (
                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '200%',
                                backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                zIndex: '999'
                            }}
                        ></div>
                        <div
                            className={classes.popup}
                            style={{
                                position: 'absolute',
                                color: 'black',
                                top: 'calc(50% + 25px)',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '1000'
                            }}
                            ref={(node) => (this.node = node)}
                        >
                            <DateRange
                                months={months}
                                direction={direction}
                                editableDateInputs={true}
                                onChange={this.handleChange}
                                moveRangeOnFirstSelection={false}
                                ranges={[this.state.selectionRange]}
                                startDatePlaceholder={'Start Date'}
                                endDatePlaceholder={'End Date'}
                                dateDisplayFormat={'MMM-d-yyyy'}
                                minDate={minDate}
                                maxDate={maxDate}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}
                            >
                                <DrcButton className={classes.actionButtons} fullWidth isPrimary onClick={this.handleSaveBtnClick}>
                                    Close
                                </DrcButton>

                                <DrcButton
                                    className={classes.actionButtons}
                                    fullWidth
                                    isSecondary
                                    onClick={() => {
                                        this.handleReset();
                                    }}
                                >
                                    Reset
                                </DrcButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

/**
 * @description Default props for DrcDateRangePicker
 */
DrcDateRangePicker.defaultProps = {
    direction: 'horizontal',
    months: 2
};

export default withStyles(styles)(DrcDateRangePicker);
