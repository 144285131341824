import React, { Component } from 'react';
import DrcSelect from './DrcSelect';
import DrcIcons from '../Utilities/DrcIcons';
import { components } from 'react-select';

const Option = (props) => {
    return (
        <components.Option {...props}>
            {DrcIcons.GetSmallBerryIcon(props.data.value)}
            <span style={{ marginLeft: '10px', lineHeight: '22px', verticalAlign: 'top' }}>{props.data.label}</span>
        </components.Option>
    );
};

const ValueContainer = ({ children, ...props }) => {
    if (!props.hasValue) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    return (
        <components.ValueContainer {...props}>
            {DrcIcons.GetSmallBerryIcon(props.selectProps.value.value)}
            <span style={{ marginLeft: '10px', lineHeight: '22px', verticalAlign: 'top' }}>{props.selectProps.value.label}</span>
        </components.ValueContainer>
    );
};

export const BerryTypeOptions = [
    { value: 1, label: 'BLACK' },
    { value: 2, label: 'BLUE' },
    { value: 3, label: 'RASP' },
    { value: 4, label: 'STRAW' }
];

class DrcBerrySelect extends Component {
    render() {
        const { onChange, value, containerStyle, ...other } = this.props;

        return (
            <DrcSelect
                name="BerryType"
                label="Berry Type"
                value={value}
                onChange={onChange}
                options={BerryTypeOptions}
                components={{ Option, ValueContainer }}
                isClearable={true}
                containerStyle={containerStyle}
                {...other}
            />
        );
    }
}

export default DrcBerrySelect;
