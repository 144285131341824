/**
 * @description list of languages supported
 * @constant LANGUAGES
 */
const LANGUAGES = [
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: '汉语', code: 'zh-CN' },
    { name: 'Français', code: 'fr' }
];

// [English, Spanish, Chinese, French]
/**
 * @description translations for commonly used words for buttons/fields/role
 * @constant COMMON_TRANSLATIONS
 */
const COMMON_TRANSLATIONS = {
    buttons: {
        okay: ['Okay', 'Bueno'],
        yes: ['Yes', 'Si'],
        no: ['No', 'No'],
        cancel: ['Cancel', 'Cancelar'],
        continue: ['Continue', 'Seguir'],
        back: ['Back', 'Regresa'],
        oops: ['Oops, Just Kidding', 'Vaya, es broma'],
        submit: ['Submit', 'Enviar'],
        abort: ['Abort', 'Abortar'],
        agree: ['Agree', 'Acordar'],
        add: ['Add', 'Añadir'],
        delete: ['Delete', 'Eliminar'],
        download: ['Download', 'Descargar'],
        save: ['Save', 'Salvar'],
        accept: ['Accept', 'Aceptar']
    },
    fields: {
        requiredField: ['This field is required', 'Este campo es requerido']
    },
    roles: {
        admin: ['Admin', 'Admin'],
        user: ['User', 'Usuario'],
        readOnly: ['Read Only', 'Solo Lectura']
    },
    devices: {
        android: ['Android', 'Android', 'Android', 'Android'],
        ios: ['iOS', 'iOS', '的iOS', 'iOS'],
        windows: ['Windows', 'Windows', 'Windows', 'Windows'],
        mac: ['Mac OS', 'Mac OS', 'Mac OS', 'Mac OS']
    },
    links: {
        go: ['Go', 'Ir', '去', 'Va'],
        fetchingRedirect: ['Fetching Redirect', 'Obteniendo redireccionamiento', '获取重定向', 'Récupération de la redirection'],
        redirectDoesNotExist: ['Redirect Does Not Exist', 'La redirección no existe', '重定向不存在', "La redirection n'existe pas"],
        redirectingNow: ['Redirecting You Now', 'Redirigiéndote ahora', '现在重定向您', 'Vous rediriger maintenant']
    }
};

/**
 *
 * @constant DuCommonTranslations
 */
const DuCommonTranslations = { COMMON_TRANSLATIONS, LANGUAGES };

export default DuCommonTranslations;
