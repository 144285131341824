import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

const styles = (theme) => ({
    check: {
        alignContent: 'flex-end',
        '& .MuiSwitch-colorSecondary': {
            color: theme.light.secondary
        },
        '& .Mui-checked': {
            color: theme.light.accent.primary
        },
        '& .Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.light.accent.primary + ' !important'
        },
        '& .MuiSwitch-track': {
            backgroundColor: theme.light.variant.primary + ' !important'
        },
        [theme.darkTheme]: {
            '& .MuiSwitch-track': {
                backgroundColor: theme.dark.text.primary + ' !important'
            }
        }
    },
    parent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    switchLabel: {
        margin: 'auto 0',
        color: theme.light.text.secondary,
        [theme.darkTheme]: {
            color: theme.dark.text.secondary
        }
    },
    required: {
        color: DuThemeUtilities.DefaultColors.primary.red
    }
});

class DrcSwitch extends React.Component {
    render() {
        const { className, classes, value, checked, onChange, children, required, disabled } = this.props;

        return (
            <div className={`${classes.parent} ${className}`}>
                <Switch disabled={disabled} className={classes.check} checked={checked} onChange={onChange} value={value} />
                <span className={classes.switchLabel}>
                    {children}
                    {required ? <span className={classes.required}>*</span> : null}
                </span>
            </div>
        );
    }
}

export default withStyles(styles)(DrcSwitch);
