import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import DrcMain from '../Components/DrcMain';
import DrcOktaWidget from '../Components/DrcOktaWidget';
import DrcImage from '../Components/DrcImage';

var styles = (theme) => ({
    login: {
        height: 'calc(100vh - 100px)',
        position: 'relative',
        '& > div': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateY(-50%) translateX(-50%)'
        },
        '& #okta-sign-in .auth-content': {
            padding: 20
        },
        '& #okta-sign-in .okta-sign-in-header': {
            display: 'none'
        },
        '& #okta-sign-in.auth-container.main-container': {
            boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.62)',
            width: 350
        },
        '& #okta-sign-in.auth-container .okta-form-label': {
            position: 'relative',
            zIndex: 1,
            margin: '-10px 0 -15px 14px'
        },
        '& #okta-sign-in .o-form .input-fix input': {
            borderRadius: '6px !important'
        },
        '& #okta-sign-in .o-form .okta-form-label label': {
            backgroundColor: '#fff',
            paddingLeft: 3,
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: '#000'
            }
        }
    },
    poly: {
        '& #okta-sign-in.auth-container #okta-signin-submit': {
            borderRadius: 25
        }
    },
    image: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -5,
        '@media (prefers-color-scheme: dark)': {
            opacity: 0.2
        }
    }
});
/**
 *
 *
 * @description Fully-featured and customized login page as per Driscoll's format which is used to authenticate user.
 * @class DrcPageLogin
 * @extends {Component}
 * @property {*} backgroundPng Background image for login page in PNG format
 * @property {*} backgroundWebP Background image for login page in WebP format
 * @example <Route path="/" exact render={(props) => (<DrcPageLogin {...props} poly backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP}/>)}/>
 */
class DrcPageLogin extends Component {
    constructor(props) {
        super(props);

        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    async componentDidMount() {
        if (this.props.setPageTitle) {
            this.props.setPageTitle(''); //Empty string makes page title go to default, which is good in this case.
        }
    }

    /**
     *
     * @description Okta login success handler
     * @param {object} res Response from okta when user log-in
     * @return {*}
     * @memberof DrcPageLogin
     */
    onSuccess(res) {
        if (res.status === 'SUCCESS') {
            return this.props.oktaAuth.signInWithRedirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    }
    /**
     *
     * @description Okta login failure handler
     * @param {*} err Okta login error message
     * @memberof DrcPageLogin
     */
    onError(err) {
        console.error('error logging in', err);
    }

    render() {
        return this.props.authState.isAuthenticated ? (
            <Redirect to={this.props.redirect || '/InitializeApplication/'} />
        ) : (
            <React.Fragment>
                <DrcMain transparent>
                    <DrcImage src={this.props.backgroundPng} webp={this.props.backgroundWebP} className={this.props.classes.image} alt="" />
                    <DrcOktaWidget
                        baseUrl={window.config.OKTA_URL}
                        onSuccess={this.onSuccess}
                        onError={this.onError}
                        className={`${this.props.classes.login} ${this.props.poly ? this.props.classes.poly : ''}`}
                        config={this.props.config || {}}
                    />
                </DrcMain>
            </React.Fragment>
        );
    }
}

export default withOktaAuth(withStyles(styles)(DrcPageLogin));
