import React from 'react';
import DrcLegend from './DrcLegend';

/**
 * @description DrcChartWrapper is a customizable wrapper component around DrcLegend which in turn is a customizable wrapper over <legend>
 * @class DrcChartWrapper
 * @property {string} title Sets the title of DrcLegend
 * @property {object} style Sets style of div wrapping DrcLegend
 * @property {*} children Child elements of wrapper
 * @example
 * <DrcChartWrapper title={'Chart Wrapper'} style={{ maxWidth: 180 }}>
 *     <p>Content of the wrapper!</p>
 * </DrcChartWrapper>
 * @extends {React.Component}
 */
class DrcChartWrapper extends React.Component {
    /**
     * @return {*}
     * @memberof DrcChartWrapper
     */
    render() {
        return (
            <div style={this.props.style}>
                <DrcLegend>{this.props.title}</DrcLegend>
                {this.props.children}
            </div>
        );
    }
}

export default DrcChartWrapper;
