import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        maxWidth: '400px',
        margin: 'auto',
        border: '1px solid #ddd',
        padding: '10px',
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary
        }
    },
    alignWithLogin: {
        marginTop: '-9px'
    },
    text: {
        textAlign: 'right'
    }
});

class DrcSignInVersionFooter extends React.Component {
    constructor(props) {
        super(props);

        var hostname = window.location.hostname.toLowerCase();
        var environment = '';

        if (hostname.includes('dev') || hostname.includes('localhost')) {
            environment = 'Development';
        } else if (hostname.includes('tst') || hostname.includes('test')) {
            environment = 'Test';
        } else if (!!props.allowAllEnvironments && hostname.includes('uat')) {
            environment = 'UAT';
        } else if ((!!props.allowAllEnvironments && hostname.includes('uat2')) || hostname.includes('qa')) {
            environment = 'UAT 2';
        } else if ((!!props.allowAllEnvironments && hostname.includes('stg')) || hostname.includes('stage')) {
            environment = 'Stage';
        } else if (!!props.allowAllEnvironments) {
            environment = 'Production';
        } else {
            environment = '';
        }

        this.state = {
            versions: (window.Versions || []).sort((a, b) => {
                return a.description.localeCompare(b.description);
            }),
            environment
        };
    }

    render() {
        if (this.state.environment.length === 0) {
            return null;
        }

        const { classes, errorMessage, selfAlign } = this.props;

        return (
            <div className={`${classes.root} ${selfAlign ? '' : classes.alignWithLogin}`}>
                {errorMessage}
                <div className="row">
                    <div className={`col-xs-6 ${classes.text}`}>Application Version:</div>
                    <div className="col-xs-6">{process.env.REACT_APP_VERSION}</div>
                </div>
                {this.state.versions.map((packageVersion) => (
                    <div className="row" key={packageVersion.package}>
                        <div className={`col-xs-6 ${classes.text}`}>{packageVersion.description}:</div>
                        <div className="col-xs-6">{packageVersion.version}</div>
                    </div>
                ))}
                {this.state.environment !== 'Production' ? (
                    <div className="row">
                        <div className={`col-xs-6 ${classes.text}`}>Environment:</div>
                        <div className="col-xs-6">{this.state.environment || 'N/A'}</div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcSignInVersionFooter);
