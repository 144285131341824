// [English, Spanish, Chinese, French]
const DRC_PAGE_DATA_MAINTENANCE_TRANSLATIONS = {
    HelpTexts: {
        removeIllegalChars: ['Please Remove Illegal Characters', 'Elimine Los Caracteres Ilegales'],
        useLargerValue: ['Please Use a Larger Value', 'Utilice un Valor Mayor'],
        useSmallerValue: ['Please Use a Smaller Value', 'Utilice un Valor Menor'],
        correctInput: ['Please Correct Input', 'Por Favor, Corrija la Entrada'],
        addValidEmail: ['Please Add a Valid Email', 'Agregue un Correo Electrónico Válido'],
        addValidUserId: ['Please Add a Valid User Id', 'Agregue una Identificación de Usuario Válida'],
        addNewType: ['Add new ${type}', 'Agregar Nuevo ${type}'],
        deleteTypeConfirmTitle: ['Are you sure you want to delete ${type}?', '¿Estás Seguro de que Quieres Eliminar ${type}?'],
        addTypeConfirmTitle: ['Are you sure you want to add ${type}?', '¿Estás Seguro de que Deseas Agregar ${type}?'],
        addNewTypeConfirmTitle: ['Are you sure you want to add new ${type}?', '¿Está seguro de que Desea Agregar un Nuevo ${type}?'],
        changeTypeConfirmTitle: ['Are you sure you want to change ${type}?', '¿Estás Seguro de que Quieres Cambiar de ${type}?'],
        addNewTypeDialog: ['Are you sure you want to add new ${type} type: "${editedValue}"?', '¿Está Seguro de que Desea Agregar un Nuevo Tipo ${type}: "${editedValue}"?'],
        editDialogText: [
            'Are you sure you want to change ${type}: "${oldValue}" to "${editedValue}"?',
            '¿Está Seguro de que Desea Cambiar ${type}: "${oldValue}" a "${editedValue}"?'
        ],
        requiredLengthTooSmall: ['Required Length Too Small', 'Longitud Requerida Demasiado Pequeño'],
        requiredLengthTooLarge: ['Required Length Too Large', 'Longitud Requerida Demasiado Grande'],
        requiredField: ['Required Field empty', 'Campo Obligatorio Vacío']
    }
};

const DrcTranslations = { DRC_PAGE_DATA_MAINTENANCE_TRANSLATIONS };

export default DrcTranslations;
