import DrcGlossaryLabel from '../Components/DrcGlossaryLabel';
import DrcGlossaryList from '../Components/DrcGlossaryList';
import { DuGlossaryUtilities } from '@driscollsinc/driscolls-react-utilities';

/**
 * @description customizable wrapper to display informative text when user hovers over a component (or) a group of components
 * @param {Object} key key of the glossary object
 * @example DrcGlossaryUtilities.get({key:'someKey',shortDescription:'some description'})
 * @return {*}
 */
var get = function(key) {
    return <DrcGlossaryLabel description={DuGlossaryUtilities.GetDescription(key)}>{key}</DrcGlossaryLabel>;
};

/**
 * @description To Get customizable list with a table, easy way to display a list of key value data which is to be sent in an array of Objects as child to the component
 * @return {*}
 */
var getGlossaryList = function() {
    return (
        <DrcGlossaryList>
            {DuGlossaryUtilities.Glossary.map((glossaryObj) => {
                return { key: glossaryObj.key, value: glossaryObj.longDescription };
            })}
        </DrcGlossaryList>
    );
};

const DrcGlossaryUtilities = {
    ...DuGlossaryUtilities,
    Get: get,
    GetGlossaryList: getGlossaryList
};

export default DrcGlossaryUtilities;
