import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DrcGlobalStyle from './DrcGlobalStyles';
import DrcThemeUtilities from '../Utilities/DrcThemeUtilities';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

const defaultFontFamily = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
].join(',');
/**
 *
 *
 * @description Driscolls format theme that apply a consistent tone to the app i.e the color of the components, darkness of the surfaces, level of shadow, appropriate opacity of ink elements, etc
 * @class DrcThemeProvider
 * @extends {Component}
 * @property {*} children This is the App component i.e <App/>
 * @property {*} theme customized theme for the app
 * @property {*} fontFamily customized fonts for the app
 * @example <DrcThemeProvider><App /></DrcThemeProvider>
 */
class DrcThemeProvider extends Component {
    /**
     *
     * @ignore
     * @return {*}
     * @memberof DrcThemeProvider
     */
    render() {
        const { children, theme, fontFamily, globalStyle } = this.props;

        var muiTheme = theme || DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green);

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={muiTheme}>
                    {children}
                    {globalStyle || <DrcGlobalStyle fontFamily={fontFamily || defaultFontFamily} />}
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

export default DrcThemeProvider;
