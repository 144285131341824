import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import DrcLegend from './DrcLegend';
import DrcButton from './DrcButton';
import DrcMain from './DrcMain';
import DrcImage from './DrcImage';
import { makeStyles } from '@material-ui/core/styles';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

const useStyles = makeStyles({
    header: {
        width: '100%',
        marginTop: 0,
        padding: '0 0 5px',
        fontSize: '2rem',
        textAlign: 'center'
    },
    headerError: {
        color: DuThemeUtilities.DefaultColors.primary.red,
        borderColor: DuThemeUtilities.DefaultColors.primary.red
    },
    loginButton: {
        width: '100%',
        margin: '12px 0 0',
        backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
        borderColor: DuThemeUtilities.DefaultColors.primary.red,
        '&:hover': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            borderColor: DuThemeUtilities.DefaultColors.primary.red
        }
    },
    image: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -5,
        '@media (prefers-color-scheme: dark)': {
            opacity: 0.2
        }
    }
});

function DrcImplicitCallback(props) {
    const classes = useStyles();
    const { authState } = useOktaAuth();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (authState.isPending === true || authState.isAuthenticated === true) {
            return;
        }

        let url = window.location.href;
        let isError = false;

        if (url.indexOf('state=') < 0) {
            isError = true;
        }

        let callbackDetails = url.substring(url.indexOf('state='));
        let callbackChunks = callbackDetails.split('&');

        let error = 'Unknown Error';
        let errorDescription = 'An unknown error has occurred, please try to login again. If this continues please seek assistance.';

        try {
            callbackChunks.forEach((chunk) => {
                if (chunk.includes('error=')) {
                    isError = true;
                    let errorTitle = chunk.split('=')[1];

                    errorTitle = errorTitle.replace(/_/gi, ' ').trim();
                    error = errorTitle.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
                } else if (chunk.includes('error_description=')) {
                    isError = true;
                    let errorInfo = chunk.split('=')[1];

                    errorDescription = errorInfo.replace(/\+|#|\//gi, ' ').trim();
                }
            });
        } catch {
            errorDescription = 'An unknown error has occurred, please try to login again. If this continues please seek assistance.';
        }

        setTitle(isError ? error : 'Successfully Authorized');
        setDescription(isError ? errorDescription : 'You are now logged in, we will take you to the application now.');
    }, [authState]);

    return (
        <DrcMain maxWidth={600}>
            <DrcImage src={props.backgroundPng} webp={props.backgroundWebP} className={classes.image} alt="" />
            <DrcLegend className={`${classes.header} ${!authState.isPending && !authState.isAuthenticated ? classes.headerError : ''}`}>
                {authState.isPending ? "Hold tight, we're authorizing you now" : authState.isAuthenticated ? 'Successfully Authorized' : title || 'Unknown Error'}
            </DrcLegend>
            <p>{description}</p>
            <LoginCallback />
            {!authState.isPending ? (
                authState.isAuthenticated ? (
                    <Redirect to={props.redirect || '/InitializeApplication/'} />
                ) : (
                    <DrcButton noStyle isPrimary to="/" className={classes.loginButton}>
                        Back to Login
                    </DrcButton>
                )
            ) : null}
        </DrcMain>
    );
}

export default DrcImplicitCallback;
