import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker } from '@material-ui/pickers';

/**
 *@ignore
 */
const styles = (theme) => ({
    root: {
        marginTop: '7px',
        marginBottom: '5px',
        '& .MuiInput-formControl': {
            marginTop: '10px'
        },
        '& .MuiSvgIcon-root': {
            color: 'black'
        }
    },
    dialog: {
        '& .MuiPickersClock-squareMask': {
            cursor: 'pointer'
        },
        '& .MuiPickersTimePickerToolbar-toolbarLeftPadding': {
            height: '20px'
        }
    },
    labelError: {
        color: theme.light.text.errorTitle,
        fontSize: '1.25rem',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

/**
 * @description DrcTimePicker gives dialog window or an inline popover to select a time. Customizable with props.
 * @property {*} width
 * @property {Object} value JS Date obj as the input value
 * @property {String} variant can be provided "standard" or "outlined" or "filled"
 * @property {*} onChange callback function to set the value as per user selection
 * @property {String} label Label for the input field
 * @property {Object} style custom styles
 * @property {String} helperText texts to display in case of error
 * @property {boolean} error True or False to display in red as an error alerts
 * @property {boolean} required True or False to mark as required
 * @property {Object} InputProps
 * @property {Object} InputLabelProps
 * @example 
 * <DrcTimePicker
        variant="standard"
        id="time-picker"
        label="Time picker"
        value={this.state.selectedDate}
        onChange={this.handleDateChange}
        KeyboardButtonProps={{
        'aria-label': 'change time',
        }}
    />
 * @class DrcTimePicker
 * @extends {React.Component}
 */
class DrcTimePicker extends React.Component {
    /**
     *
     * @returns {*}
     * @memberof DrcTimePicker
     */
    render() {
        const { classes, width, value, variant, onChange, label, style, helperText, required, InputProps, InputLabelProps, ...other } = this.props;
        return (
            <KeyboardTimePicker
                className={classes.root}
                style={{ width: width || '100%', ...style }}
                margin="dense"
                inputVariant={variant || 'outlined'}
                label={
                    required ? (
                        <span>
                            {label || ''} <span className={classes.labelError}>*</span>
                        </span>
                    ) : (
                        label || ''
                    )
                }
                value={value}
                onChange={onChange}
                helperText={helperText || ''}
                error={(helperText || '').length > 0}
                InputProps={{
                    ...InputProps
                }}
                InputLabelProps={{
                    ...InputLabelProps
                }}
                DialogProps={{ className: classes.dialog }}
                {...other}
            />
        );
    }
}

export default withStyles(styles)(DrcTimePicker);
