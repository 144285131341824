import React, { Component } from 'react';
import DrcImage from './DrcImage';
import TopPng from '../Images/Background_Top.png';
import TopWebP from '../Images/Background_Top.webp';
import BottomPng from '../Images/Background_Top.png';
import BottomWebP from '../Images/Background_Bottom.webp';

/**
 * @description DrcBackgroundImages is a styled BackgroundImage
 * @class DrcBackgroundImages
 * @example <DrcBackgroundImages />
 * @extends {React.Component}
 */

class DrcBackgroundImages extends Component {
    /**
     * @return {*}
     * @memberof DrcBackgroundImages
     */
    render() {
        return (
            <React.Fragment>
                <DrcImage
                    src={TopPng}
                    webp={TopWebP}
                    className="ImgBackgroundTop"
                    style={{
                        position: 'fixed',
                        top: '5vh',
                        right: '0',
                        zIndex: '-1',
                        width: '60vw',
                        maxWidth: '400px'
                    }}
                    alt=""
                />
                <DrcImage
                    src={BottomPng}
                    webp={BottomWebP}
                    className="ImgBackgroundBottom"
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        zIndex: '-1',
                        width: '60vw',
                        maxWidth: '400px'
                    }}
                    alt=""
                />
            </React.Fragment>
        );
    }
}

export default DrcBackgroundImages;
