import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    panel: {
        margin: '0 auto 15px',
        padding: '15px',
        border: 'solid 1px ' + theme.light.border.middle,
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.primary,
            border: 'solid 1px ' + theme.dark.border.middle
        }
    },
    error: {
        backgroundColor: theme.light.error,
        color: theme.light.text.error,
        [theme.darkTheme]: {
            backgroundColor: theme.dark.error,
            color: theme.dark.text.error
        }
    }
});

class DrcPanel extends React.Component {
    render() {
        const { maxWidth, style, children, error, backgroundColor, id, className, classes } = this.props;

        return (
            <div
                id={id}
                className={`${classes.panel} ${error ? classes.error : ''} ${className}`}
                style={{
                    maxWidth: maxWidth || '900px',
                    backgroundColor: backgroundColor,
                    ...style
                }}
            >
                {children}
            </div>
        );
    }
}

export default withStyles(styles)(DrcPanel);
