import { DuUploadUtilities } from '@driscollsinc/driscolls-react-utilities';
import React from 'react';

/**
 * @description used to upload files with validation
 * @param {*} returnFunction callback function specifying details of uploaded files validation
 * @param {*} ref unique ref key
 * @param {*} statusFunction status function specifying the status details of the each file validation
 * @param {*} acceptableExtensionsArray array of accepted file extensions
 * @returns {*} input field
 */
const fileInput = (returnFunction, ref, statusFunction, acceptableExtensionsArray) => {
    var theArray = acceptableExtensionsArray.join();

    return (
        <input
            ref={ref}
            type="file"
            id="input"
            onChange={(t) => {
                DuUploadUtilities.HandleFiles(t.currentTarget.files, returnFunction, statusFunction, acceptableExtensionsArray);
            }}
            accept={theArray}
        />
    );
};

const DrcUploadUtilities = {
    FileInput: fileInput
};

export default DrcUploadUtilities;
