import React, { useState, useEffect } from 'react';
import { useOktaAuth, SecureRoute } from '@okta/okta-react';
import DrcPageNotAuthorized from '../Pages/DrcPageNotAuthorized';
import DrcPageLoadingUser from '../Pages/DrcPageLoadingUser';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
/**
 * @description The DrcSecureGroupRoute is an authentication widget & uses Okta Sign-In authentication
 * @author stephen.weessies
 * @version 1.1
 * @property {Array} groupsAllowed Array of groups that can access the route
 * @property {String} path 'url path string
 * @component
 * @property {jsx} loadingPage page that is showed when the component is loaded for which the route is defined
 * @property {jsx} unauthorizedPage page that is showed when the user has no access to the route
 * @example
 * <DrcSecureGroupRoute path="/Issuance/" exact component={Issuance} groupsAllowed={allGroups} />*/
const DrcSecureGroupRoute = (props) => {
    const { authState } = useOktaAuth();
    const [groups, setGroups] = useState([]);
    /**
     * @description This is the method that is called to check the route's validity
     * @version 1.1
     * @return {boolean}
     * @memberof DrcSecureGroupRoute
     */
    const checkPath = () => {
        const { path } = props;
        var simplePath = path.indexOf(':') >= 0 ? path.substring(0, path.indexOf(':')) : path;

        //Doing simple check to just to see if its close
        if (window.location.pathname.indexOf(simplePath) !== 0) {
            return false;
        }

        //TODO: Need to do a full check to make sure it matches with any number of parameters.

        return true;
    };
    /**
     * @description This is the method that sets group to the state of this component
     * @version 1.1
     * @return {*}
     * @memberof DrcSecureGroupRoute
     */
    const checkForGroups = () => {
        if (!checkPath()) {
            return;
        }

        if (authState.isPending) {
            return;
        }

        if (!authState.isAuthenticated) {
            if (groups.length != 0) {
                setGroups([]);
            }
        } else {
            var tokenGroups = DuAuthenticationUtilities.GetGroups(authState.accessToken) || [];

            if (groups.length != tokenGroups.length) {
                setGroups(tokenGroups);
            }
        }
    };

    const renderWrapper = (renderProps) => {
        const C = props.component;
        return <C {...renderProps} role={props.groups || []} />;
    };

    checkForGroups();

    if (!checkPath()) {
        return loadingPage || <DrcPageLoadingUser />;
    }

    const { exact, path, groupsAllowed, loadingPage, unauthorizedPage } = props;

    if (authState.isAuthenticated === false) {
        return unauthorizedPage || <DrcPageNotAuthorized />;
    } else if (authState.isPending || !groups) {
        return loadingPage || <DrcPageLoadingUser />;
    }

    //Use the groups that are available now.
    var requiresGroup = (groupsAllowed || []).length > 0;
    var userGroups = groups || [];
    var isAllowed = requiresGroup ? userGroups.some((r) => groupsAllowed.indexOf(r) >= 0) : true;

    //If we find groups or find none but token was successfully received then show Not Authorized page.
    return isAllowed ? <SecureRoute path={path} render={renderWrapper} exact={exact} /> : unauthorizedPage || <DrcPageNotAuthorized />;
};

export default DrcSecureGroupRoute;
