import { DuExcelUtilities } from '@driscollsinc/driscolls-react-utilities';
import React from 'react';
/**
 * @description This utility is used to upload excel files
 * @author stephen.weessies
 * @version 1.1
 * @param {function} returnFunction function called before return
 * @param {object} ref The ref passed to the input
 * @param {function} statusFunction The function which is executed while the excel file is processing
 * @return {jsx} An input field
 * @memberof DrcExcelUtilities
 * @example
 * <div style={{ display: 'none' }}>{DrcExcelUtilities.FileInput(this.handleExcelFile, this.inputElement, this.statusUpdate)}</div>
 */
const fileInput = (returnFunction, ref, statusFunction, read_opts) => {
    return (
        <input
            ref={ref}
            type="file"
            id="input"
            onChange={(t) => {
                DuExcelUtilities.HandleFiles(t.currentTarget.files, returnFunction, statusFunction, read_opts);
            }}
            accept=".xls,.xlsx,.xlsb"
        />
    );
};

const DrcExcelUtilities = {
    FileInput: fileInput
};

export default DrcExcelUtilities;
