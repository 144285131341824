import React from 'react';
import { TextField, withStyles, Divider, InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Material UI component for input field
// Event handlers and state management handled completely in parent component
// Defaults to variant outlined and type text
// Handles all props that TextField does
// can resize by assigning sm a value

var styles = (theme) => ({
    '@global': {
        '.MuiInputBase-root.MuiOutlinedInput-root legend span': {
            fontSize: '.75rem',
            color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color: theme.dark.text.primary + (theme.dark.text.primary.includes('#') ? (theme.dark.text.primary.length > 5 ? 'aa' : 'a') : '')
            }
        },
        '.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(14px, 12px) scale(1)',
            color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color: theme.dark.text.primary + (theme.dark.text.primary.includes('#') ? (theme.dark.text.primary.length > 5 ? 'aa' : 'a') : '')
            }
        },
        '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -4px) scale(.75)',
            color: theme.light.text.primary + (theme.light.text.primary.includes('#') ? (theme.light.text.primary.length > 5 ? 'aa' : 'a') : ''),
            [theme.darkTheme]: {
                color: theme.dark.text.primary + (theme.dark.text.primary.includes('#') ? (theme.dark.text.primary.length > 5 ? 'aa' : 'a') : '')
            }
        },
        '.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.border.middle + theme.important,
            [theme.darkTheme]: {
                borderColor: theme.dark.border.middle + theme.important
            }
        },
        '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.text.primary + theme.important,
            [theme.darkTheme]: {
                borderColor: theme.dark.text.primary + theme.important
            }
        },
        '.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.accent.primary + theme.important,
            [theme.darkTheme]: {
                borderColor: theme.dark.accent.primary + theme.important
            }
        },
        '.MuiInputBase-root': {
            color: theme.light.text.primary,
            height: '35px',
            [theme.darkTheme]: {
                color: theme.dark.text.primary
            }
        },
        '.MuiInputBase-root .MuiButtonBase-root.MuiIconButton-root': {
            marginRight: '-15px'
        }
    },
    root: {
        marginTop: 7,
        marginBottom: 5
    },
    error: {
        color: theme.light.text.errorTitle + ' !important',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    }
});

class DrcInput extends React.Component {
    onChange = (event) => {
        if (this.props.filterChanged) {
            this.props.filterChanged({ value: event.target.value, label: event.target.value, type: this.props.name });
        }

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    handleClose = (event) => {
        if (this.props.onClose) {
            this.props.onClose(event);
        }
    };

    render() {
        const {
            label,
            type,
            variant,
            width,
            style,
            classes,
            className,
            helperText,
            filterChanged,
            onChange,
            stickyLabel,
            InputProps,
            InputLabelProps,
            dispatch,
            required,
            isMoney,
            isDateRange,
            ...other
        } = this.props;

        return (
            <TextField
                className={`${classes.root} ${className ? className : ''}`}
                style={style}
                fullWidth={true}
                margin="dense"
                label={
                    required ? (
                        <span>
                            {label} <span className={classes.error}>*</span>
                        </span>
                    ) : (
                        label
                    )
                }
                placeholder={this.props.placeholder || 'Input Value...'}
                variant={variant || 'outlined'}
                type={type || 'text'}
                helperText={helperText}
                error={(helperText || '').length > 0}
                onChange={this.onChange}
                InputLabelProps={InputLabelProps}
                InputProps={{
                    startAdornment: isMoney ? (
                        <InputAdornment position="start">
                            $
                            <Divider orientation="vertical" style={{ height: 20, width: 2, marginLeft: 10 }} />
                        </InputAdornment>
                    ) : null,
                    endAdornment: isDateRange ? (
                        <InputAdornment position="end">
                            <IconButton aria-label="open date range picker" onClick={this.handleClose} onMouseDown={this.handleClose}>
                                <CloseIcon />
                            </IconButton>

                            <IconButton aria-label="open date range picker">
                                <DateRangeIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : null,

                    ...InputProps
                }}
                {...other}
            />
        );
    }
}

export default withStyles(styles)(DrcInput);
