import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcButton from './DrcButton';
import DrcImage from './DrcImage';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import Black_Medium_Png from '../Images/Black_Medium.png';
import Black_Medium_Webp from '../Images/Black_Medium.webp';
import Blue_Medium_Png from '../Images/Blue_Medium.png';
import Blue_Medium_Webp from '../Images/Blue_Medium.webp';
import Rasp_Medium_Png from '../Images/Rasp_Medium.png';
import Rasp_Medium_Webp from '../Images/Rasp_Medium.webp';
import Straw_Medium_Png from '../Images/Straw_Medium.png';
import Straw_Medium_Webp from '../Images/Straw_Medium.webp';

const BERRY_STRING_VALUES = ['EMPTY', 'BLACK', 'BLUE', 'RASP', 'STRAW'];
const BERRY_BLACK = 1;
const BERRY_BLUE = 2;
const BERRY_RASP = 3;
const BERRY_STRAW = 4;

/**
 *@ignore
 */
var styles = (theme) => ({
    berryBtn: {
        margin: '0',
        display: 'block',
        borderRadius: 0,
        width: '100%'
    },
    variantVertical: {
        borderBottom: 'none !important',
        '&:last-of-type': {
            borderBottom: `1px solid ${theme.palette.primary.main} !important`
        }
    },
    variantHorizontal: {
        borderRight: 'none !important',
        '&:last-of-type': {
            borderRight: `1px solid ${theme.palette.primary.main} !important`
        }
    },
    berryFontBlack: {
        color: DuThemeUtilities.DefaultColors.primary.purple
    },
    berryFontBlue: {
        color: DuThemeUtilities.DefaultColors.primary.blue
    },
    berryFontRasp: {
        color: DuThemeUtilities.DefaultColors.primary.pink
    },
    berryFontStraw: {
        color: DuThemeUtilities.DefaultColors.primary.red
    },
    selected: {
        '&.berryBlack': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.purple + ' !important'
        },
        '&.berryBlue': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.blue + ' !important'
        },
        '&.berryRasp': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.pink + ' !important'
        },
        '&.berryStraw': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red + ' !important'
        },
        '& .berryFont': {
            color: DuThemeUtilities.DefaultColors.text.white.light + ' !important'
        },
        '& .berryIcon': {
            boxShadow: 'inset 0 0 10px 0px #888'
        }
    },
    berryFont: {
        fontFamily: 'BlogScript',
        textTransform: 'none',
        width: '100%',
        display: 'inline-block',
        fontSize: '1.5rem'
    },
    berryFontVerticalOffset: {
        marginTop: 20
    },
    berryIcon: {
        height: 60,
        width: 60,
        borderRadius: 30,
        display: 'inline-block',
        backgroundColor: '#FFF',
        '& img': {
            marginTop: 10,
            height: 40
        }
    },
    btnContentContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    btnTextContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    btnIconContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    horizontalList: {
        display: 'flex'
    },
    hidden: {
        display: 'none'
    }
});

/**
 * @description The DrcBerryList is a styled dropdown berry list in the Driscolls format and is customizable
 * @author just.a.username
 * @version 1.0
 * @class DrcBerryList
 * @example
 * let disabledBerries = [1];
 * let hiddenBerries = [2];
 * let selectedBerry = 1;
 * <DrcBerryList onClick={} disabledBerries={disabledBerries} hiddenBerries={hiddenBerries} selectedBerry={selectedBerry}/>
 * @property {number} selectedBerry berry type in number (i.e 1,2,3 or 4)
 * @property {array} disabledBerries List of berry types in number (i.e 1,2,3 or 4) to be disabled in the list
 * @property {array} hiddenBerries List of berry types in number (i.e 1,2,3 or 4) to be hidden in the list
 * @property {boolean} useText specify berry type in text
 * @property {json} berryFontStyle font style for berry
 * @property {json} iconSizeStyle icon size style for berry
 * @property {json} berryIconStyle icon style for berry
 * @property {boolean} isHorizontal list in horizontal direction
 * @property {json} className className
 * @extends {React.Component} */
class DrcBerryList extends Component {
    /**
     * Creates an instance of DrcBerryList.
     * @param {*} props
     * @memberof DrcBerryList
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            berryType: this.props.selectedBerry || 0
        };
    }

    /**
     *
     * @private
     * @memberof DrcBerryList
     */
    componentDidUpdate(prevProps) {
        if (prevProps.selectedBerry !== 0 && this.props.selectedBerry === 0) this.setState({ berryType: 0 });
    }

    /**
     *
     * @description method to handle berry selection
     * @param {*} val
     * @memberof DrcBerryList
     */
    handleBerryClick = (val) => {
        if (val === this.state.berryType) {
            return;
        }

        this.setState({
            berryType: val
        });

        const callback = this.props.onClick || (() => {});

        callback(this.props.useText ? BERRY_STRING_VALUES[val] : val);
    };

    /**
     *
     * @description method to show a berry type, reduces complexity in renderer
     * @param {int} berryId Is the berry's value i.e. Straw is 4
     * @memberof DrcBerryList
     */
    addBerryUi = (berryId) => {
        const { classes, disabledBerries, hiddenBerries, iconSizeStyle, berryBtnStyle, berryFontStyle, berryIconStyle, isHorizontal } = this.props;
        const { berryType } = this.state;

        const btnBorderStyle = isHorizontal ? classes.variantHorizontal : classes.variantVertical;

        let title = 'Unknown';
        let cssClass = '';
        let cssFontClass = '';
        let imagePng;
        let imageWebp;

        switch (berryId) {
            case BERRY_BLACK:
                title = 'Blackberry';
                cssClass = 'berryBlack';
                cssFontClass = classes.berryFontBlack;
                imagePng = Black_Medium_Png;
                imageWebp = Black_Medium_Webp;
                break;
            case BERRY_BLUE:
                title = 'Blueberry';
                cssClass = 'berryBlue';
                cssFontClass = classes.berryFontBlue;
                imagePng = Blue_Medium_Png;
                imageWebp = Blue_Medium_Webp;
                break;
            case BERRY_RASP:
                title = 'Raspberry';
                cssClass = 'berryRasp';
                cssFontClass = classes.berryFontRasp;
                imagePng = Rasp_Medium_Png;
                imageWebp = Rasp_Medium_Webp;
                break;
            case BERRY_STRAW:
                title = 'Strawberry';
                cssClass = 'berryStraw';
                cssFontClass = classes.berryFontStraw;
                imagePng = Straw_Medium_Png;
                imageWebp = Straw_Medium_Webp;
                break;
        }

        return (
            <DrcButton
                isSecondary
                noStyle
                disabled={(disabledBerries || []).includes(berryId)}
                className={`${classes.berryBtn} ${btnBorderStyle} ${berryBtnStyle} ${berryType === berryId ? classes.selected : ''} ${cssClass} ${
                    (hiddenBerries || []).includes(berryId) ? classes.hidden : ''
                }`}
                onClick={() => this.handleBerryClick(berryId)}
            >
                {!isHorizontal ? (
                    <div className="row">
                        <div className="col-xs-9">
                            <span className={`berryFont ${classes.berryFont} ${classes.berryFontVerticalOffset} ${cssFontClass} ${berryFontStyle}`}>{title}</span>
                        </div>
                        <div className="col-xs-3">
                            <span className={`berryIcon ${classes.berryIcon} ${berryIconStyle}`}>
                                <DrcImage className={iconSizeStyle} src={imagePng} webp={imageWebp} />
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className={classes.btnContentContainer}>
                        <div className={classes.btnIconContainer}>
                            <span className={`berryIcon ${classes.berryIcon} ${berryIconStyle}`}>
                                <DrcImage className={iconSizeStyle} src={imagePng} webp={imageWebp} />
                            </span>
                        </div>
                        <div className={classes.btnLabelContainer}>
                            <span className={`berryFont ${classes.berryFont} ${cssFontClass} ${berryFontStyle}`}>{title}</span>
                        </div>
                    </div>
                )}
            </DrcButton>
        );
    };

    /**
     *
     * @return {*}
     * @memberof DrcBerryList
     */
    render() {
        const { className, classes, isHorizontal } = this.props;
        const listDirection = isHorizontal ? classes.horizontalList : '';

        return (
            <div className={`${listDirection} ${className}`}>
                {this.addBerryUi(BERRY_BLACK)}
                {this.addBerryUi(BERRY_BLUE)}
                {this.addBerryUi(BERRY_RASP)}
                {this.addBerryUi(BERRY_STRAW)}
            </div>
        );
    }
}

export default withStyles(styles)(DrcBerryList);
