import React from 'react';
import DrcButton from './DrcButton';
import DrcFilterTag from './DrcFilterTag';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

/**
 * @description The DrcFilterTagList is used a handy component whith abilities to control and view filters, it can use to add, apply and clear filters
 * @author stephen.weessies
 * @version 1.1
 * @class DrcFilterTagList
 * @example
 *   <DrcFilterTagList
 *                   enableSave
 *                   onSaveClick={this.onSaveButtonClick}
 *                   selectedFilters={this.props.selectedFilters}
 *                   setFilter={this.setPoFilter}
 *                   clearFilters={() => {
 *                       this.props.clearPoFilters();
 *                       this.applyFilters(null, true);
 *                   }}
 *                   clearOneFilter={this.clearOneFilter}
 *                   masterData={this.state.masterData}
 *                   search={this.applyFilters}
 *                   variableOptions={this.getVariableOptions()}
 *               />
 * @property {boolean} enableSave shows the save button to save the filters
 * @property {function} onSaveClick function that handles what save button would do
 * @property {function} setFilter setfilter handler
 * @property {function} clearFilters  handler called when the clear filter button is clicked
 * @property {function} clearOneFilter  handler called when the one filter is cleared
 * @property {function} search  handler called when search button is clicked
 * @property {Array} masterData  data for maipulation
 * @extends {React.Component} */
class DrcFilterTagList extends React.Component {
    /**
     * Creates an instance of DrcFilterTagList.
     * @memberof DrcFilterTagList
     * @return {*}
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            filters: []
        };

        this.getCorrectName = this.getCorrectName.bind(this);
        this.getSelectedFilters = this.getSelectedFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    getCorrectName = function (index) {
        var variableName = Object.keys(this.props.selectedFilters)[index];
        variableName = variableName.charAt(0).toUpperCase() + variableName.slice(1);
        return variableName.replace(/([A-Z])/g, ' $1').trim();
    };
    /**
     * @description  This is the method that is called when the component mounts
     * @author stephen.weessies
     * @version 1.1
     * @return {*}
     * @memberof DrcFilterTagList
     */
    componentDidMount() {
        this.setState({
            filters: this.getSelectedFilters()
        });
    }
    /**
     * @description This is the method that is called when the props or state updated
     * @author stephen.weessies
     * @version 1.1
     * @return {*}
     * @memberof DrcFilterTagList
     */
    componentDidUpdate() {
        var filters = this.getSelectedFilters(),
            lastIndex = filters.length - 1;
        if (filters.length !== this.state.filters.length || filters[lastIndex].key !== this.state.filters[lastIndex].key) {
            this.setState({
                filters
            });
        }
    }
    /**
     * @description This is the method that is called when the single filter is cleared
     * @author stephen.weessies
     * @version 1.1
     * @return {*}
     * @memberof DrcFilterTagList
     */
    removeFilter(name) {
        this.props.clearOneFilter(name);
        this.updateFilters();
    }
    getSetFiltersLength = () => {
        var selectedFilters = Object.entries(this.props.selectedFilters || [])
            .map((val, index) => {
                return {
                    option: val[1]
                };
            })
            .filter((val) => {
                return val.option !== null && val.option.isSet && val.option.value;
            });
        return selectedFilters.length;
    };
    /**
     * @description This is the method is used to get slected filters
     * @author stephen.weessies
     * @version 1.1
     * @return {*}
     * @memberof DrcFilterTagList
     */
    getSelectedFilters() {
        //Converting the array of types into the template
        var selectedFilters = Object.entries(this.props.selectedFilters || [])
            .map((val, index) => {
                return {
                    index: val[1] != null ? val[1].index : Math.random() * -1,
                    option: val[1],
                    control: (
                        <DrcFilterTag
                            key={val[1] != null ? val[0] : -1}
                            option={val[1]}
                            index={val[1] != null ? val[1].index : -1}
                            selectedFilters={this.props.selectedFilters}
                            variableName={Object.keys(this.props.selectedFilters)[index]}
                            setFilter={this.props.setFilter}
                            masterData={this.props.masterData}
                            variableOptions={this.props.variableOptions}
                            removeFilter={this.removeFilter}
                        />
                    )
                };
            })
            .filter((val) => {
                return val.option !== null && val.option.isSet;
            });

        var maxIndex = selectedFilters.length;

        //Sorting the filters alphabetically
        selectedFilters = selectedFilters.sort((a, b) => {
            return a.control.props.index - b.control.props.index;
        });

        //Only going to have the control so we can easily show it
        selectedFilters = selectedFilters.map((val) => {
            return val.control;
        });

        //Adding in a blank one so the user can add more
        if (maxIndex !== this.props.variableOptions.length) {
            selectedFilters.push(
                <DrcFilterTag
                    key={maxIndex}
                    index={maxIndex}
                    showAddBtn={true}
                    selectedFilters={this.props.selectedFilters}
                    updateFilters={this.updateFilters}
                    setFilter={this.props.setFilter}
                    masterData={this.props.masterData}
                    variableOptions={this.props.variableOptions}
                    removeFilter={this.removeFilter}
                />
            );
        }
        return selectedFilters;
    }
    /**
     * @description This is the method that is used to get updated filters whenever user changes filters
     * @author stephen.weessies
     * @version 1.1
     * @return {*}
     * @memberof DrcFilterTagList
     */
    updateFilters() {
        this.setState({
            filters: this.getSelectedFilters()
        });
    }
    /**
     * @description This is the method that is called when the clear filter button is clicked
     * @author stephen.weessies
     * @version 1.1
     * @return {*}
     * @memberof DrcFilterTagList
     */
    clearFilters() {
        this.setState(
            {
                filters: [
                    <DrcFilterTag
                        key={0}
                        index={0}
                        clearFlag={Math.random()}
                        selectedFilters={this.props.selectedFilters}
                        updateFilters={this.updateFilters}
                        setFilter={this.props.setFilter}
                        masterData={this.props.masterData}
                        variableOptions={this.props.variableOptions}
                        removeFilter={this.removeFilter}
                    />
                ]
            },
            this.props.clearFilters
        );
    }
    /**
     *
     *
     * @return {*}
     * @memberof DrcFilterTagList
     */
    render() {
        return (
            <section id="filterSection">
                <div id="poHiddenFilters" className="row">
                    <div className="col-xs-9 col-md-10">
                        <div className="row">{this.state.filters}</div>
                    </div>
                    <div className="col-xs-3 col-md-2">
                        <div className="row" style={{ marginTop: 5 }}>
                            <div className={this.props.enableSave ? 'col-xs-4' : 'col-xs-6'}>
                                <DrcButton isPrimary fullWidth title="Search" onClick={this.props.search} style={{ margin: '0', height: '35px', minWidth: '40px' }}>
                                    <SearchIcon />
                                </DrcButton>
                            </div>
                            <div className={this.props.enableSave ? 'col-xs-4' : 'col-xs-6'}>
                                <DrcButton
                                    isSecondary
                                    fullWidth
                                    title="Clear Filters"
                                    disabled={this.state.filters.length < 2}
                                    onClick={this.clearFilters}
                                    style={{ margin: '0', height: '35px', minWidth: '40px' }}
                                >
                                    <DeleteIcon />
                                </DrcButton>
                            </div>
                            {this.props.enableSave && (
                                <div className={this.props.enableSave ? 'col-xs-4' : 'col-xs-6'}>
                                    <DrcButton
                                        isSecondary
                                        fullWidth
                                        title="Save"
                                        disabled={this.getSetFiltersLength() < 1}
                                        onClick={this.props.onSaveClick}
                                        style={{ margin: '0', height: '35px', minWidth: '40px' }}
                                    >
                                        <SaveIcon />
                                    </DrcButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DrcFilterTagList;
