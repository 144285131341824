import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withOktaAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
import Warning from '@material-ui/icons/Warning';
import DrcAuthenticationUtilities from '../Utilities/DrcAuthenticationUtilities';

/**
 *@ignore
 */
const styles = (theme) => ({
    heading: {
        display: 'inline-block',
        margin: '20px 20px 0 20px',
        position: 'relative',
        top: '-15px'
    },
    icon: {
        fontSize: '4rem'
    }
});

/**
 * @description The DrcPageNotAuthorized is a styled not authorized screen in the Driscolls format and is customizable
 * @version 1.0
 * @class DrcPageNotAuthorized
 * @example
 * <DrcPageNotAuthorized />
 * @property {string} logOutUrl Logout url - default - '/Logout/'
 * @property {string} logInUrl LogIn url - default - '/'
 * @extends {React.Component} */
class DrcPageNotAuthorized extends Component {
    /**
     * Creates an instance of DrcPageNotAuthorized.
     * @param {*} props
     * @memberof DrcPageNotAuthorized
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: null
        };

        this.checkForAuthentication = this.checkForAuthentication.bind(this);
    }

    /**
     *
     * @description method to check for user authentication
     * @memberof DrcPageNotAuthorized
     */
    async checkForAuthentication() {
        let isAuthenticated = this.props.authState.isAuthenticated;

        if (this.state.isAuthenticated != isAuthenticated) {
            this.setState({ isAuthenticated });
        }

        if (!isAuthenticated) {
            var credentials = DrcAuthenticationUtilities.GetUsernameAndPasswordFromQuery(this.props.location.search);

            if (credentials !== null) {
                DrcAuthenticationUtilities.SignIn(credentials.username, credentials.password);
            }
        }
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        this.checkForAuthentication();
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.checkForAuthentication();
    }

    /**
     *
     *
     * @return {*}
     * @memberof DrcPageNotAuthorized
     */
    render() {
        const { logOutUrl, logInUrl, classes } = this.props;

        return (
            <DrcMain transparent>
                <Helmet>
                    <title>Not Authorized</title>
                </Helmet>
                <DrcPanel error maxWidth="500px" style={{ textAlign: 'center' }}>
                    <Warning className={classes.icon} />
                    <h1 className={classes.heading}>You are not authorized!</h1>
                    <Warning className={classes.icon} />
                    <br />
                    {this.state.isAuthenticated ? (
                        <p>
                            Please <Link to={logOutUrl || '/LogOut/'}>Sign-Out</Link> and try with better credentials.
                        </p>
                    ) : (
                        <p>
                            Please go back to the <Link to={logInUrl || '/'}>Sign-In</Link> page and try again.
                        </p>
                    )}
                </DrcPanel>
            </DrcMain>
        );
    }
}

export default withOktaAuth(withStyles(styles)(withRouter(DrcPageNotAuthorized)));
