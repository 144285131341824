var glossary = [];

var addAll = function(glossaryList) {
    glossary = glossaryList;
};

var addOne = function(key, shortDescription, longDescription) {
    if (getGlossaryObject(key) !== null) {
        var index = glossary.findIndex((glossaryObj) => glossaryObj.key === key);

        glossary.splice(index, 1);
    }

    glossary.push({ key, shortDescription, longDescription });
};

var getList = function() {
    return glossary.map((glossaryObj) => {
        return { key: glossaryObj.key, value: glossaryObj.longDescription };
    });
};

var getGlossaryObject = function(key) {
    var item = glossary.find((glossaryObj) => glossaryObj.key === key);

    return item ? item : null;
};

var getDescription = function(key, shortDescription = true) {
    var glossaryObject = getGlossaryObject(key);

    if (glossaryObject !== null) {
        return shortDescription ? glossaryObject.shortDescription : glossaryObject.longDescription;
    }

    return '';
};

var replace = function(input) {
    //TODO: Need to implement this to inspect the input and replace all occurrences of words that are in the glossary with the glossary labels

    return input;
};

const DuGlossaryUtilities = {
    Glossary: glossary,
    AddAll: addAll,
    AddOne: addOne,
    //Get: get,
    GetDescription: getDescription,
    //GetGlossaryList: getGlossaryList
    GetList: getList
};

export default DuGlossaryUtilities;
