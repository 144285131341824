import React from 'react';
import DrcMain from '../Components/DrcMain';
import DrcLegend from '../Components/DrcLegend';
import DrcReleaseInfo from '../Components/DrcReleaseInfo';
import DrcDatepicker from '../Components/DrcDatepicker';
import DrcButton from '../Components/DrcButton';
import DrcSelect from '../Components/DrcSelect';

class DrcPageReleases extends React.Component {
    constructor(props) {
        super(props);

        var minDate = new Date();
        var maxDate = new Date();

        this.props.releases.forEach((release) => {
            if (new Date(release.scheduledReleaseUtc) < minDate) {
                minDate = new Date(release.scheduledReleaseUtc);
            }

            if (new Date(release.scheduledReleaseUtc) > maxDate) {
                maxDate = new Date(release.scheduledReleaseUtc);
            }
        });

        var releaseOptions = this.props.releases.map((r) => {
            return { value: r.version, label: r.version };
        });

        var maxVersion = releaseOptions[0];
        var minVersion = releaseOptions[releaseOptions.length - 1];

        this.state = {
            filteredReleases: this.props.releases,
            releaseOptions,
            minVersion,
            maxVersion,
            minDate,
            maxDate
        };

        this.search = this.search.bind(this);
        this.clear = this.clear.bind(this);
        this.handleMinDateChange = this.handleMinDateChange.bind(this);
        this.handleMaxDateChange = this.handleMaxDateChange.bind(this);
        this.searchVersions = this.searchVersions.bind(this);
        this.minVersionUpdate = this.minVersionUpdate.bind(this);
        this.maxVersionUpdate = this.maxVersionUpdate.bind(this);
    }

    search() {
        //Find all relevant releases
        var filteredReleases = [];
        const { minDate, maxDate } = this.state;

        var tasks = [];

        this.props.releases.forEach((release) => {
            var date = new Date(release.scheduledReleaseUtc);

            if (date >= minDate && date <= maxDate) {
                filteredReleases.push(release);
            }
        });

        //If there is only one just show it
        if (filteredReleases.length <= 1) {
            this.setState({ filteredReleases: filteredReleases });
            return;
        }

        filteredReleases = filteredReleases.sort((relA, relB) => {
            var relASplit = relA.version.split('.');
            var relBSplit = relB.version.split('.');

            while (relASplit.length < 3) {
                relASplit.push('0');
            }

            while (relBSplit.length < 3) {
                relBSplit.push('0');
            }

            return Number(relASplit[0]) === Number(relBSplit[0])
                ? Number(relASplit[1]) === Number(relBSplit[1])
                    ? Number(relASplit[2]) > Number(relBSplit[2])
                    : Number(relASplit[1]) > Number(relBSplit[1])
                : Number(relASplit[0]) > Number(relBSplit[0]);
        });

        var tasks = [];

        filteredReleases
            .map((rel) => rel.relatedTasks)
            .forEach((taskList) => {
                if (taskList && taskList.length > 0) {
                    tasks = [...tasks, ...taskList];
                }
            });

        //If there is more than one then we need to have a static title
        var releaseCombined = {
            version: filteredReleases[0].version,
            controlsVersion: filteredReleases[0].controlsVersion,
            title: 'Combined Release',
            description: filteredReleases
                .map((rel) => rel.description)
                .reduce((accu, elem) => {
                    return accu === null ? [elem] : [...accu, elem];
                }, null),
            relatedTasks: tasks,
            scheduledReleaseUtc: filteredReleases[0].scheduledReleaseUtc
        };

        this.setState({ filteredReleases: [releaseCombined] });
    }

    searchVersions() {
        //Find all relevant releases
        const { minVersion, maxVersion } = this.state;

        var tasks = [];

        var filteredReleases = this.props.releases.filter((release) => {
            return release.version >= minVersion.value && release.version <= maxVersion.value;
        });

        //If there is only one just show it
        if (filteredReleases.length <= 1) {
            this.setState({ filteredReleases: filteredReleases });
            return;
        }

        filteredReleases = filteredReleases.sort((relA, relB) => {
            var relASplit = relA.version.split('.');
            var relBSplit = relB.version.split('.');

            while (relASplit.length < 3) {
                relASplit.push('0');
            }

            while (relBSplit.length < 3) {
                relBSplit.push('0');
            }

            return Number(relASplit[0]) === Number(relBSplit[0])
                ? Number(relASplit[1]) === Number(relBSplit[1])
                    ? Number(relASplit[2]) > Number(relBSplit[2])
                    : Number(relASplit[1]) > Number(relBSplit[1])
                : Number(relASplit[0]) > Number(relBSplit[0]);
        });

        var tasks = [];

        filteredReleases
            .map((rel) => rel.relatedTasks)
            .forEach((taskList) => {
                if (taskList && taskList.length > 0) {
                    tasks = [...tasks, ...taskList];
                }
            });

        //If there is more than one then we need to have a static title
        var releaseCombined = {
            version: filteredReleases[0].version,
            controlsVersion: filteredReleases[0].controlsVersion,
            title: 'Combined Release',
            description: filteredReleases
                .map((rel) => rel.description)
                .reduce((accu, elem) => {
                    return accu === null ? [elem] : [...accu, elem];
                }, null),
            relatedTasks: tasks,
            scheduledReleaseUtc: filteredReleases[0].scheduledReleaseUtc
        };

        this.setState({ filteredReleases: [releaseCombined] });
    }

    clear() {
        var minDate = new Date();
        var maxDate = new Date();

        this.props.releases.forEach((release) => {
            if (new Date(release.scheduledReleaseUtc) < minDate) {
                minDate = new Date(release.scheduledReleaseUtc);
            }

            if (new Date(release.scheduledReleaseUtc) > maxDate) {
                maxDate = new Date(release.scheduledReleaseUtc);
            }
        });

        var maxVersion = this.state.releaseOptions[0];
        var minVersion = this.state.releaseOptions[this.state.releaseOptions.length - 1];

        this.setState({ filteredReleases: this.props.releases, minDate, maxDate, minVersion, maxVersion });
    }

    handleMinDateChange(value) {
        if (value) {
            value.setHours(0, 0, 0, 0);
        }

        if (this.state.minDate !== value) {
            this.setState({ minDate: value });
        }
    }

    handleMaxDateChange(value) {
        if (value) {
            value.setHours(0, 0, 0, 0);
        }

        if (this.state.maxDate !== value) {
            this.setState({ maxDate: value });
        }
    }

    minVersionUpdate(obj) {
        this.setState({
            minVersion: obj
        });
    }

    maxVersionUpdate(obj) {
        this.setState({
            maxVersion: obj
        });
    }

    render() {
        const { children } = this.props;
        var { filteredReleases, minDate, maxDate } = this.state;

        return (
            <DrcMain maxWidth="900px">
                {children}
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <DrcDatepicker label={'Min Date'} value={minDate} onChange={(val) => this.handleMinDateChange(val)} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <DrcDatepicker label={'Max Date'} value={maxDate} onChange={(val) => this.handleMaxDateChange(val)} />
                    </div>
                    <div className="col-xs-6 col-md-2">
                        <DrcButton fullWidth isPrimary onClick={this.search}>
                            Combine Dates
                        </DrcButton>
                    </div>
                    <div className="col-xs-6 col-md-2">
                        <DrcButton fullWidth isSecondary onClick={this.clear}>
                            Clear
                        </DrcButton>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <DrcSelect value={this.state.minVersion} onChange={this.minVersionUpdate} label="Min Version">
                            {this.state.releaseOptions}
                        </DrcSelect>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <DrcSelect value={this.state.maxVersion} onChange={this.maxVersionUpdate} label="Max Version">
                            {this.state.releaseOptions}
                        </DrcSelect>
                    </div>
                    <div className="col-xs-6 col-md-2">
                        <DrcButton fullWidth isPrimary onClick={this.searchVersions}>
                            Combine Versions
                        </DrcButton>
                    </div>
                </div>
                <DrcLegend>Releases</DrcLegend>
                {filteredReleases.map((release) => (
                    <DrcReleaseInfo
                        key={release.version}
                        title={release.title}
                        version={release.version}
                        controlsVersion={release.controlsVersion}
                        utilityVersion={release.utilityVersion}
                        oneRingVersion={release.oneRingVersion}
                        relatedTasks={release.relatedTasks}
                        scheduledReleaseUtc={release.scheduledReleaseUtc}
                    >
                        {release.description}
                    </DrcReleaseInfo>
                ))}
            </DrcMain>
        );
    }
}

export default DrcPageReleases;
