/**
 * @description sizes of different output devices
 * @constant DrcMediaSizes
 */
const DrcMediaSizes = {
    mobileM: '500px',
    mobileL: '767px',
    tablet: '1023px',
    laptop: '1199px',
    desktop: '1200px'
};

export default DrcMediaSizes;
