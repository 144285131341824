import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

var styles = (theme) => ({
    root: {
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.light.error,
        color: theme.light.text.inverted,
        textShadow: theme.light.variant.primary + '3px 2px 5px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.error,
            color: theme.dark.text.secondary,
            textShadow: 'none'
        }
    },
    rootMessage: {
        backgroundColor: theme.light.accent.primary,
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.accent.primary
        }
    },
    rootWarning: {
        backgroundColor: theme.light.warning,
        color: theme.light.text.primary,
        textShadow: theme.light.variant.secondary + ' 3px 2px 8px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.warning,
            color: theme.dark.text.primary,
            textShadow: theme.dark.text.primary + '3px 2px 8px'
        }
    },
    title: {
        marginTop: theme.spacing(1),
        fontSize: '1.5rem'
    }
});

class DrcBanner extends React.Component {
    render() {
        const { isError, isWarning, title, children, classes } = this.props;

        var isMessage = !isError && !isWarning;

        return (
            <Paper className={`${classes.root} ${isMessage ? classes.rootMessage : ''} ${isWarning ? classes.rootWarning : ''}`}>
                <h3 className={classes.title}>{title}</h3>
                <p>{children}</p>
            </Paper>
        );
    }
}

export default withStyles(styles)(DrcBanner);
