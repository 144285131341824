import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
    circularLabel: {
        position: 'relative',
        top: '-15px',
        left: '-40px',
        width: '40px',
        display: 'inline-block',
        textAlign: 'center'
    },
    linearLabel: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        padding: '10px'
    }
});

class DrcProgress extends React.Component {
    render() {
        const { isCircular, value, variant, style, classes, ...other } = this.props;

        var variantToUse = variant || 'determinate';

        const progress = (
            <React.Fragment>
                {isCircular ? (
                    <CircularProgress style={style} variant={variantToUse} value={value} {...other} />
                ) : (
                    <LinearProgress style={style} variant={variantToUse} value={value} {...other} />
                )}
                {variantToUse === 'determinate' || variantToUse === 'static' ? <span className={isCircular ? classes.circularLabel : classes.linearLabel}>{value}%</span> : null}
            </React.Fragment>
        );

        return progress;
    }
}

export default withStyles(styles)(DrcProgress);
