import DuDateUtilities from './DuDateUtilities';

const allowedStringValuesRegex = RegExp(/[.,?\/#!$%\^&\*;:{}=\-_`'"~()]/);
const allowedNumberValuesRegex = RegExp(/^[0-9]+$/);
const allowedIntegerValuesRegex = RegExp(/^[-]?[0-9]*[.]?[0-9]{0,2}$/);
const allowedDecimalValuesRegex = RegExp(/^[-]?[0-9]*[.]?[0-9]*$/);
const allowedAlphaNumericValuesRegex = RegExp(/[a-zA-Z0-9]+$/);
const emailRegex = RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
const userIdRegex = RegExp(/^([a-zA-Z0-9_\-]+)\.([a-zA-Z0-9_\-]+)$/);

/**
 * @param {string} element The string to be validated
 * @param {string} type The type of the element to be validated
 * @return {boolean} true or false if the element is valid for type it is tested
 */
const isValid = (element, type) => {
    switch (type) {
        case 'number': {
            if (!allowedNumberValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'text': {
            if (!allowedStringValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'decimal': {
            if (!allowedDecimalValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'alphaNumeric': {
            if (!allowedAlphaNumericValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'integer': {
            if (!allowedIntegerValuesRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'email': {
            if (!emailRegex.test(element)) {
                return false;
            } else return true;
        }
        case 'required': {
            if (element === '') {
                return false;
            } else return true;
        }
        default:
            return false;
    }
};
/**
 * @param {number} index Index for finding status
 * @param {number} total The type of the element to be validated
 * @param {array} errorList The type of the element to be validated
 * @param {date} timeStarted The type of the element to be validated
 * @return {object} validationResultStatus object
 */
const validationResultStatus = (index, total, errorList, timeStarted) => {
    let errorRowCount = ((errorList || []).filter((error) => error) || []).length;
    let errorTotalCount = 0;

    if (errorRowCount > 0) {
        let errorCountList = errorList.map((l) => {
            return l.errorCount;
        });
        errorTotalCount = errorCountList.reduce((a, b) => a + b, 0);
    }

    return {
        index,
        total,
        validCount: total - errorRowCount,
        errorRowCount,
        errorTotalCount,
        errorList: errorList || [],
        elapsedTime: DuDateUtilities.TimeSpanToString(timeStarted, new Date())
    };
};
/**
 * @param {number} index Index for which error message is to be added
 * @param {array} errorList The error list to add error
 * @param {string} funcName The function that created the error
 * @param {string} message The error message to be added
 * @return {*}
 */
const validationAddError = (errorList, index, funcName, message) => {
    if (errorList[index]) {
        errorList[index].errorCount++;
        errorList[index].errors.push({ funcName, message });
    } else {
        errorList[index] = { errorCount: 1, errors: [{ funcName, message }] };
    }
};
/**
 * @description Used to validate data return a promise
 * @param {array} data Index for which error message is to be added
 * @param {array} columns columns to validate
 * @param {function} statusCallback The callback function
 * @param {boolean} verbose if it is verbose
 * @return {*}
 */
const validateData = async (data, columns, statusCallback = () => {}, verbose = false, statusUpdateEvery = 100) => {
    return new Promise((resolve, reject) => {
        if (!data || data.length <= 0 || !columns || columns.length <= 0) {
            reject(validationResultStatus(0, 0, [], new Date()));
            return;
        }

        let startTime = new Date();
        let errorList = [];

        for (let i = 0; i < data.length; i++) {
            validateRow(i, data[i], columns, errorList);

            if (i % statusUpdateEvery === 0) {
                statusCallback(validationResultStatus(i, data.length, verbose ? errorList : [], startTime));
            }
        }

        resolve(validationResultStatus(data.length, data.length, errorList, startTime));
    });
};
/**
 * @description used in validating rows
 * @param {number} index The index to be validated in a row
 * @param {array} columns The columns to be validated
 * @param {array} errorList The errorlist
 * @return {*}
 */
const validateRow = (index, row, columns, errorList) => {
    for (let i = 0; i < columns.length; i++) {
        if (columns[i].key) {
            validateColumn(index, row[columns[i].key], columns[i], errorList);
        }
    }
};
/**
 * @description used in validating columns
 * @param {number} index The index to be validated in a column
 * @param {array} columns The columns to be validated
 * @param {string} data The data to be validated
 * @param {array} errorList The errorlist
 * @return {object}
 */
const validateColumn = (index, data, column, errorList) => {
    if (typeof column.validationType === 'string') {
        //validationType; regex
        //TODO: Handle this case like Maintenance Page
    } else {
        let validationFuncList = [];
        if (typeof column.validationType === 'function') {
            validationFuncList.push(column.validationType);
        } else if (typeof column.validationType === 'array' && typeof column.validationType[0] === 'function') {
            validationFuncList = column.validationType;
        }

        for (let i = 0; i < validationFuncList.length; i++) {
            var result = validationFuncList[i](data, column.label || column.key, column.params || column.regex);

            if (result !== null && typeof result === 'object') {
                validationAddError(errorList, index, result.funcName || 'Unknown Function', result.message || 'Unknown Error');
            }
        }
    }
};
/**
 * @description used in validating text
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @return {object}
 */
const validateText = (input, columnName) => {
    if (!isValid(input, 'text')) {
        return { funcName: 'Text', message: `Bad characters provided in column "${columnName}"` };
    }
    return null;
};
/**
 * @description used in validating number
 * @param {number} input The input to be validated
 * @param {string} columnName The column to be validated
 * @param {object} params The params which include min and max for a number that is to be validated
 * @return {object}
 */
const validateNumber = (input, columnName, params) => {
    if (!isValid(input, 'number')) {
        return { funcName: 'Number', message: `Bad number provided in column "${columnName}"` };
    }

    if (params) {
        let min = (params || {}).min || 0;
        let max = (params || {}).max || 255;

        if (input < min) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too small` };
        } else if (input > max) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too large` };
        }
    }

    return null;
};
/**
 * @description used in validating length of data in a column
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @param {object} params The params which include min and max for a number that is to be validated
 * @return {object}
 */
const validateLength = (input, columnName, params) => {
    let min = (params || {}).min || 0;
    let max = (params || {}).max || 255;
    let length = input.toString().length;

    if (length < min) {
        return { funcName: 'Column Length', message: `Data in column "${columnName}" is too short` };
    } else if (length > max) {
        return { funcName: 'Column Length', message: `Data in column "${columnName}" is too long` };
    }
    return null;
};
/**
 * @description used in validating a floating point number
 * @param {number} input The input to be validated
 * @param {string} columnName The column to be validated
 * @return {object}
 */
const validateDecimal = (input, columnName) => {
    if (!isValid(input, 'decimal')) {
        return { funcName: 'Decimal', message: `Bad decimal in column "${columnName}"` };
    }
    return null;
};
/**
 * @description used in validating regex
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @param {object} params The params to be validated against
 * @return {object}
 */
const validateRegex = (input, columnName, params) => {
    let regex = params || '';

    if (!regex.test(input)) {
        return { funcName: 'Regex', message: `Data in column "${columnName}" is not valid` };
    }
    return null;
};
/**
 * @description used in validating email
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @return {object}
 */
const validateEmail = (input, columnName) => {
    if (!isValid(input, 'email')) {
        return { funcName: 'Email', message: `Bad email in column "${columnName}"` };
    }
    return null;
};
/**
 * @description used in validating date
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @param {object} params The params to be validated against
 * @return {object}
 */
const validateDate = (input, columnName, params) => {
    if (!input instanceof Date) {
        return { funcName: 'Date', message: `Bad date in column "${columnName}"` };
    }

    let minDate = params.minDate || null;
    let maxDate = params.maxDate || null;

    if (minDate !== null && input < minDate) {
        return { funcName: 'Date', message: `Date in column "${columnName}" is too far in the past` };
    } else if (maxDate !== null && input > maxDate) {
        return { funcName: 'Date', message: `Date in column "${columnName}" is too dar in the future` };
    }
    return null;
};

/**
 * @description used to validate invlid data in a column
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @return {object}
 */
const validateRequired = (input, columnName) => {
    if (input === null || input === undefined || input === '') return { funcName: 'List', message: `Matching data not found in column "${columnName}"` };
    return null;
};
/**
 * @description used to validate invlid data in a list
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @param {object} params The params to be validated against
 * @return {object}
 */
const validateDataInList = (input, columnName, params) => {
    let list = params || [];

    let isObjectList = list.length > 0 && list[columnName];

    if (isObjectList && !list[columnName].includes(input)) {
        return { funcName: 'List', message: `Matching data not found in column "${columnName}"` };
    } else if (!list.includes(input)) {
        return { funcName: 'List', message: `Matching data not found in column "${columnName}"` };
    }
    return null;
};
/**
 * @description used to validate alpha numeric data
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @return {object}
 */
const validateAlphaNumeric = (input, columnName) => {
    if (!isValid(input, 'alphaNumeric')) {
        return { funcName: 'AlphaNumeric', message: `Bad characters provided in column "${columnName}"` };
    }
    return null;
};
/**
 * @description used to validate range
 * @param {string} input The input to be validated
 * @param {string} columnName The column to be validated
 * @param {object} params The params to be validated against
 * @return {object}
 */
const validateRange = (input = '', columnName, params) => {
    let inputArr = input.toString().split('-');
    for (let i = 0; i < inputArr.length; i++)
        if (!isValid(inputArr[i].trim(), 'number')) {
            return { funcName: 'Number', message: `Bad number provided in column "${columnName}"` };
        }

    if (params) {
        let min = (params || {}).min || 0;
        let max = (params || {}).max || 255;

        if (input < min) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too small` };
        } else if (input > max) {
            return { funcName: 'Number', message: `Number in column "${columnName}" is too large` };
        }
    }

    return null;
};

const DuValidationUtilities = {
    STRING_REGEXP: allowedStringValuesRegex,
    NUMBER_REGEXP: allowedNumberValuesRegex,
    INTEGER_REGEXP: allowedIntegerValuesRegex,
    DECIMAL_REGEXP: allowedDecimalValuesRegex,
    ALPHA_NUMERIC_REGEXP: allowedAlphaNumericValuesRegex,
    EMAIL_REGEXP: emailRegex,
    USER_ID_REGEX: userIdRegex,
    isValid: isValid,
    ValidateRequired: validateRequired,
    ValidateData: validateData,
    ValidateText: validateText,
    ValidateNumber: validateNumber,
    ValidateLength: validateLength,
    ValidateDecimal: validateDecimal,
    ValidateRegex: validateRegex,
    ValidateEmail: validateEmail,
    ValidateDate: validateDate,
    ValidateDataInList: validateDataInList,
    ValidateAlphaNumeric: validateAlphaNumeric,
    ValidateRange: validateRange
};

export default DuValidationUtilities;
