const SEVERITY = {
    Message: 'message',
    Warning: 'warning',
    Error: 'error'
};

const loggers = {
    Console: 'console'
};

const trackers = {
    NewRelic: 'newrelic'
};

let currentSetup = [];
let valuesApplied = [];

const setup = (trackers) => {
    if (currentSetup.length !== 2) {
        currentSetup = [loggers.Console, trackers.NewRelic];
    }
};

const apply = (key, value) => {
    valuesApplied[key] = value;

    if (currentSetup.some((l) => l === trackers.NewRelic)) {
        applyNewRelic(key, value);
    }
};

const applyNewRelic = (key, value) => {
    if (window && window.newrelic) {
        window.newrelic.setCustomAttribute(key, value || '');
    }
};

const action = (severity, message, obj) => {
    if (currentSetup.some((l) => l === loggers.Console)) {
        actionConsole(severity, message);
    }

    if (currentSetup.some((l) => l === trackers.NewRelic)) {
        actionNewRelic(severity, message, obj);
    }
};

const actionConsole = (severity, message, obj) => {
    switch ((severity || SEVERITY.Message).toLowerCase()) {
        case SEVERITY.Error:
            console.error(message, obj);
            break;
        case SEVERITY.Warning:
            console.warn(message, obj);
            break;
        case SEVERITY.Message:
        default:
            console.log(message, obj);
            break;
    }
};

const actionNewRelic = (severity, message, obj) => {
    if (window && window.newrelic) {
        window.newrelic.addPageAction(message, obj);
    }
};

const DuLogger = {
    Setup: setup,
    Apply: apply,
    Action: action,
    log: (message, obj) => {
        action(SEVERITY.Message, message, obj);
    },
    warn: (message, obj) => {
        action(SEVERITY.Warn, message, obj);
    },
    error: (message, obj) => {
        action(SEVERITY.Error, message, obj);
    },
    SEVERITY: SEVERITY,
    Loggers: loggers,
    Trackers: trackers
};

export default DuLogger;
