// DrcTabs.js
// This component helps in rendering material ui tabs with dynamic routing.

// Props:
// selectedMenuIndex: Arg type integer. Index of the selected menu item
// registerRoutes: Arg type boolean. Function that registers route dynamically for all the menu items.
// menuItems(recommended): Arg type Array of object.
// Each menu item object (recommended)
// tabName: Name of menu item, (supports JSX expressions)
// tabIndex: Integer, should be unique
// component: Component to be rendered on tab selection.
// route: Arg type String or Array of Strings.
// Eg 1: [`/RanchOverview/:ranch`, `/RanchOverview`], //add parameterized routes first in the order
// Eg 2: `/RanchOverview`
// isMultiRoute: Boolean, specify if multiple routes are for same component
// defaultRoute: String, (recommended in case of multiple routes)
// isDefault: Boolean, to denote if the item is the default tab from list of menu items
// groupsAllowed: Array of groups

import React, { useState } from 'react';
import { Tabs, withStyles, Tab } from '@material-ui/core';
import { withRouter, NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import DrcSecureGroupRoute from './DrcSecureGroupRoute';
import { SecureRoute } from '@okta/okta-react';

let styles = (theme) => ({
    root: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(0.5)
    },
    indicator: {
        display: `none`
    },
    tabItem: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightMedium,
        margin: '0 16px',
        lineHeight: '24px',
        textDecoration: 'none',
        color: `${theme.palette.primary.main} !important`,
        padding: 0,
        minHeight: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            background: DuThemeUtilities.DefaultColors.text.white.light,
            borderRadius: 10,
            padding: 6,
            margin: '0 12px 0 0',
            '@media (prefers-color-scheme: dark)': {
                background: DuThemeUtilities.DefaultColors.text.black.light
            }
        }
    },
    activeTabItem: {
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        opacity: 1,
        pointerEvents: 'none'
    }
});

const DrcTabs = (props) => {
    const { menuItems, classes, selectedMenuIndex, centered, registerRoutes, fullWidth, textColor, indicator, simple } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const renderMenuItems = () => {
        return menuItems.map((item) => {
            return simple ? (
                <Tab key={item.tabIndex} label={item.tabName} className={`${classes.tabItem} ${selectedTab === item.tabIndex ? classes.activeTabItem : ''}`} />
            ) : (
                <Tab
                    key={item.tabIndex}
                    label={item.tabName}
                    component={NavLink}
                    disableRipple={true}
                    className={classes.tabItem}
                    activeClassName={`${classes.activeTabItem}`}
                    to={`${props.match.path}${item.isMultiRoute ? item.defaultRoute : item.route}`}
                />
            );
        });
    };

    /**
     * If a route is not specified, handles menu item change and passes selected index to parent component
     * @param value selected tab index
     */
    const handleChange = (event, value) => {
        const { handleMenuItemChange } = props;

        setSelectedTab(value);

        if (handleMenuItemChange) {
            handleMenuItemChange(value);
        }
    };

    /**
     * Registers routes for all the menu items provided via props
     */
    const handleRegisterRoutes = () => {
        if (!registerRoutes || menuItems.length === 0) {
            return null;
        }

        const defaultTab = menuItems.find((item) => item.isDefault || item.tabIndex === 0);

        return (
            <Switch>
                {menuItems.map((item) => {
                    return item.isMultiRoute ? (
                        item.route.map((i) => {
                            return i === item.defaultRoute ? (
                                <DrcSecureGroupRoute
                                    path={`${props.match.path}${i}`}
                                    key={`${props.match.path}${item.route}`}
                                    exact
                                    component={item.component}
                                    groupsAllowed={item.groupsAllowed}
                                />
                            ) : (
                                <DrcSecureGroupRoute
                                    path={`${props.match.path}${i}`}
                                    key={`${props.match.path}${item.route}`}
                                    component={item.component}
                                    groupsAllowed={item.groupsAllowed}
                                />
                            );
                        })
                    ) : (
                        <DrcSecureGroupRoute
                            path={`${props.match.path}${item.route}`}
                            key={`${props.match.path}${item.route}`}
                            component={item.component}
                            groupsAllowed={item.groupsAllowed}
                        />
                    );
                })}
                <SecureRoute
                    exact
                    path={`${props.baseLocation || props.match.path}`}
                    render={() => <Redirect replace to={`${props.match.path}${defaultTab.isMultiRoute ? defaultTab.defaultRoute : defaultTab.route}`} />}
                />
                <Route render={() => <Redirect replace to={`/NotAuthorized`} />} />
            </Switch>
        );
    };

    return (
        <React.Fragment>
            <Tabs
                initialselectedindex={selectedMenuIndex}
                value={selectedTab}
                classes={{ root: classes.root, indicator: classes.indicator }}
                onChange={!registerRoutes ? handleChange : undefined}
                centered={centered ? centered : false}
                fullwidth={fullWidth ? fullWidth : ''}
                textColor={textColor ? textColor : 'inherit'}
                indicatorColor={indicator ? indicator.toString() : 'secondary'}
            >
                {renderMenuItems()}
            </Tabs>
            {handleRegisterRoutes()}
        </React.Fragment>
    );
};

export default withRouter(withStyles(styles)(DrcTabs));
