import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0
    },
    chip: {
        margin: theme.spacing(0.5)
    }
});

class DrcChips extends Component {
    state = {
        chipData: this.props.chips || [] // chips prop should be an array of [{key,label}]
    };

    handleDelete = (chipToDelete) => () => {
        if (this.props.onDelete) this.props.onDelete(chipToDelete);
        this.setState({ chipData: this.state.chipData.filter((chip) => chip.key !== chipToDelete.key) });
    };

    addUniqueKeyToChips = () => {
        let chips = this.props.chips || [];

        if (!chips.length || chips[0].hasOwnProperty('key')) {
            return chips;
        }

        chips = chips.map((e, indx) => {
            e.key = indx + 1;
            return e;
        });

        return chips;
    };

    render() {
        const { classes, sort, variant, color, size, deleteIcon, disableDelete, icon, image, letter } = this.props;

        let chips = this.addUniqueKeyToChips();

        if (sort === 'Asc') {
            chips.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
        } else if (sort === 'Desc') {
            chips.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
        }

        return (
            <React.Fragment>
                {chips.length ? (
                    <React.Fragment>
                        {chips.map((data) => {
                            return (
                                <Chip
                                    key={data.key}
                                    variant={variant || 'outlined'}
                                    color={color}
                                    size={size || 'small'}
                                    deleteIcon={deleteIcon}
                                    icon={icon}
                                    label={data.label}
                                    onDelete={disableDelete ? null : this.handleDelete(data)}
                                    avatar={image ? <Avatar src={image} /> : letter ? <Avatar>{letter}</Avatar> : null}
                                    className={classes.chip}
                                />
                            );
                        })}
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(DrcChips);
