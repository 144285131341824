import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Basic tooltip with arrows.
// Arrows supported for top bottom left and right positions.
// Props:
// tipText: Type string. Sets tooltip label
// placement: Type string. Sets position of tooltip
//           top, bottom, left, right
/**
 *@ignore
 */
const styles = (theme) => ({
    button: {
        margin: theme.spacing(1)
    },
    arrowPopper: arrowGenerator(theme.palette.grey[700]),
    arrow: {
        position: 'absolute',
        fontSize: 10,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid'
        }
    },
    tooltip: {
        fontSize: '1rem'
    }
});

/**
 *
 * @description method to style arrow of tooltip based on placement value
 * @param {*} color
 * @memberof DrcTooltip
 */
function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            borderColor: 'white',
            borderWidth: '1px',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`
            }
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`
            }
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '2em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`
            }
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '2em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`
            }
        }
    };
}

/**
 * @description The DrcTooltip is a styled Tooltip in the Driscolls format and is customizable
 * @version 1.0
 * @class DrcTooltip
 * @example
 * <DrcTooltip tipText="Tooltip text" placement="top">
        <span>Tool tip</span>
    </DrcTooltip>
 *
 * @property {string} tipText tool tip text
 * @property {string} placement position of the text (i.e top, bottom, left, right)
 * @extends {React.Component} */
class DrcTooltip extends React.Component {
    state = {
        arrowRef: null
    };

    /**
     * @description to handle change of arrow reference
     * @param {*} node
     * @memberof DrcTooltip
     */
    handleArrowRef = (node) => {
        this.setState({
            arrowRef: node
        });
    };

    /**
     *
     *
     * @return {*}
     * @memberof DrcTooltip
     */
    render() {
        const { tipText, placement, classes, ...other } = this.props;
        return (
            <Tooltip
                {...other}
                title={
                    <React.Fragment>
                        {tipText}
                        {/*<span className={classes.arrow} ref={this.handleArrowRef} />*/}
                    </React.Fragment>
                }
                placement={placement}
                classes={{
                    popper: classes.arrowPopper,
                    tooltip: classes.tooltip
                }}
                /*PopperProps={{
                    popperOptions: {
                        modifiers: {
                            arrow: {
                                enabled: Boolean(this.state.arrowRef),
                                element: this.state.arrowRef
                            }
                        }
                    }
                }}*/
                disableFocusListener={!tipText || tipText.length <= 0}
                disableHoverListener={!tipText || tipText.length <= 0}
                disableTouchListener={!tipText || tipText.length <= 0}
            >
                {this.props.children}
            </Tooltip>
        );
    }
}

/**
 *
 * @description to validate the data passed in props
 * @memberof DrcTooltip
 */
DrcTooltip.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DrcTooltip);
