import DrcMediaSizes from './DrcMediaSizes';

const DrcMediaQueries = {
    mobileM: `(max-width: ${DrcMediaSizes.mobileM})`,
    mobileL: `(max-width: ${DrcMediaSizes.mobileL})`,
    tablet: `(max-width: ${DrcMediaSizes.tablet})`,
    laptop: `(max-width: ${DrcMediaSizes.laptop})`,
    desktop: `(min-width: ${DrcMediaSizes.desktop})`
};

export default DrcMediaQueries;
