import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { DuCache } from '@driscollsinc/driscolls-react-utilities';
import DrcDialog from './DrcDialog';
import DrcButton from './DrcButton';

/**
 * @ignore
 */
const styles = (theme) => ({
    bar: {
        '& .MuiSnackbarContent-root': {
            minWidth: '80vw'
        }
    }
});



const HIDE_COOKIE_KEY = 'HideCookieAlert';


/**
 * @description DrcCookieMessage is a styled cookie message 
 * @class DrcCookieMessage
 * @example <DrcCookieMessage />
 * @property {* } none No properties are needed on this component
 * @extends {React.Component}
 */

class DrcCookieMessage extends React.Component {

    /**
        * Creates an instance of DrcCookieMessage.
        * @param {*} props
        * @memberof DrcCookieMessage
        * 
    */
    constructor(props) {
        super(props);

        this.state = {
            showCookieAlert: false,
            showCookieDetails: false
        };
    }

    /**
     * @ignore
     * @memberof DrcCookieMessage
     */
    async componentDidMount() {
        var hideAlert = (await DuCache.Get(HIDE_COOKIE_KEY)) || false;

        this.setState({
            showCookieAlert: !hideAlert,
            showCookieDetails: false
        });
    }

    /**
     * @private
     * @memberof DrcCookieMessage
     * @description AcceptMethod() triggered with the accept button. Stores cookie and hides alert
     */
    acceptCookies = () => {
        DuCache.Save(HIDE_COOKIE_KEY, true);
        this.setState({ showCookieAlert: false, showCookieDetails: false });
    };

    /**
     * @private
     * @memberof DrcCookieMessage
     * @description shows cookie details window.
     */
    showCookieDetails = () => {
        this.setState({ showCookieDetails: true });
    };

    /**
     *
     * @private
     * @memberof DrcCookieMessage
     * @description hides cookie details window.
     */
    hideCookieDetails = () => {
        this.setState({ showCookieDetails: false });
    };

    /**
     * @description render method for the DrcCookieMessage,  part of the react component lifecyle
     * @return {*} the view for this component
     * @memberof DrcCookieMessage
     */
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Snackbar
                    open={this.state.showCookieAlert}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    message="This website requires the use of cookies. Please accept below in order to continue using this website"
                    action={
                        <React.Fragment>
                            <DrcButton isPrimary onClick={this.acceptCookies}>
                                Accept
                            </DrcButton>
                            <DrcButton isSecondary onClick={this.showCookieDetails}>
                                More Info
                            </DrcButton>
                        </React.Fragment>
                    }
                    className={classes.bar}
                />
                <DrcDialog
                    open={this.state.showCookieDetails}
                    title="How we use cookies"
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary onClick={this.hideCookieDetails} floatRight>
                                Close
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.acceptCookies} floatRight>
                                Accept and Close
                            </DrcButton>
                        </React.Fragment>
                    }
                >
                    <hr />
                    We use cookies in making sure valid users are logged in properly.
                    <br />
                    <br />
                    We also track anonymous usage statistics in order to provide a better experience for our users.
                    <br />
                    <br />
                    We never will sell or use this data to target individual users.
                    <br />
                </DrcDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcCookieMessage);
