import React from 'react';

/**
 
 * @example <DrcIconLabel />
 * @example <DrcIconLabel />
 * @class DrcIconLabel
 * @extends {React.Component}
 */
class DrcIconLabel extends React.Component {

    /**
     * @ignore
     * @return {*} 
     * @memberof DrcIconLabel
     */
    render() {
        const { className, style, titleStyle, icon, children } = this.props;

        return (
            <span className={className} style={style}>
                {icon}
                <span style={{ marginLeft: '10px', ...titleStyle }}>{children}</span>
            </span>
        );
    }
}

export default DrcIconLabel;
