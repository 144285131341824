import React from 'react';
import DrcUploadUtilities from '../Utilities/DrcUploadUtilities';
import DrcButton from './DrcButton';
import DrcDialog from './DrcDialog';
import DrcBackdrop from './DrcBackdrop';
import { DuUploadUtilities, DuThemeUtilities, DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withStyles } from '@material-ui/core/styles';
// Use DrcIcon
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

var styles = (theme) => ({
    dragArea: {
        width: '100%',
        height: 200,
        border: '2px dashed #888',
        padding: 35,
        margin: '40px 0 10px',
        borderRadius: 10,
        textAlign: 'center',
        justifyContent: 'center'
    },
    uploadBtn: {
        textTransform: 'none'
    },
    submitBtn: {
        width: 150,
        margin: '20px auto',
        display: 'block',
        textAlign: 'center'
    }
});
/**
 * @description DrcFileUpload is used to upload files
 * @class DrcFileUpload
 * @property {function} removeUpload Removes uploaded file
 * @property {function} parsed callback function which return an object of file details (name,data etc)
 * @property {Array} fileTypeOptions array of accepted file extensions
 * @property {Boolean} enableMultiSheet true if excel has multiple sheets data to be read
 * @extends {React.Component}
 * @example
 * <DrcFileUpload parsed={()=>{}} removeUpload={()=>{}} />
 */
class DrcFileUpload extends React.Component {
    /**
     * Creates an instance of DrcFileUpload.
     * @param {*} props
     * @memberof DrcFileUpload
     */
    constructor(props) {
        super(props);

        this.state = {
            fileName: '',
            isDropInitiated: false,
            sheetSelected: '',
            file: null,
            stats: {},
            sheetDialogOpen: false,
            isLoading: false
        };

        this.dropElement = React.createRef();
        this.inputElement = React.createRef();
    }
    /**
     * @ignore
     */
    componentDidMount() {
        //this.connectDrop();
        //this.setState({ fileName: this.props.fileName });
        this.props.removeUpload(this.removeUploadedFile.bind(this));
    }

    /**
     * @description removes uploaded file on click of cancel
     * @memberof DrcFileUpload
     */
    removeUploadedFile() {
        this.inputElement.current.value = '';
        //Needed for component to rerender
        this.setState({ fileName: '' });
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        this.connectDrop();
    }
    /**
     * @description Shows a loader with Status message on uploading file
     * @param {Object} status Status/updates of the file being uploaded
     * @memberof DrcFileUpload
     */
    statusUpdate = (status) => {
        console.log(status.message);

        if (status.message === 'Starting to Open File') {
            this.showLoadingScreen(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>Processing File (Step 1 of 2)</h4>
                    <div>It can take a few seconds to a couple of minutes based on the file size</div>
                </React.Fragment>
            );
        }
    };

    /**
     * @description method to upload file on Drag/Drop
     * @memberof DrcFileUpload
     */
    connectDrop = () => {
        if (!this.state.isDropInitiated && this.dropElement.current != null) {
            this.setState({ isDropInitiated: true });
            DuUploadUtilities.CreateFileDrop(this.dropElement.current, this.handleFile, this.statusUpdate, this.props.fileTypeOptions);
        }
    };
    /**
     * @description callback function having details of file upload
     * @param {*} data Uploaded files data
     * @param {Object} stats specifies the status details of the each file upload
     * @memberof DrcFileUpload
     */
    handleFile = async (data, stats) => {
        if (data === null) {
            this.setState({
                fileName: <div style={{ color: DuThemeUtilities.DefaultColors.primary.red }}>{stats.message}</div>
            });
            this.hideLoadingScreen();
            return;
        }

        this.hideLoadingScreen();
        if (this.props.enableMultiSheet) {
            this.setState({ fileName: stats.fileName });
            this.props.parsed({ data: data, fileName: stats.fileName });
        } else if ((data.SheetNames || []).length === 1) {
            this.handleSheet(data, data.SheetNames[0], stats);
        } else {
            //open a dialog to let user choose
            this.setState({
                file: data,
                stats: stats,
                sheetDialogOpen: true,
                sheetSelected: ''
            });
        }
    };

    /**
     * @description Used to process data of the sheet
     * @param {*} data Data present in the sheet
     * @param {String} sheetName Name of the sheet/file
     * @param {Object} stats Status details of file being processed
     * @memberof DrcFileUpload
     */
    handleSheet = async (data, sheetName, stats) => {
        const processSheetStartTime = new Date();
        this.showLoadingScreen(
            <React.Fragment>
                <h4 style={{ fontSize: '1.5rem' }}>Processing Sheet (Step 2 of 2)</h4>
                <div>It can take a few seconds to a couple of minutes based on the file size</div>
            </React.Fragment>
        );

        const readData = await DuUploadUtilities.ReadRows(data, sheetName);
        this.hideLoadingScreen();

        if (readData.length <= 0) {
            this.setState({
                fileName: <div style={{ color: DuThemeUtilities.DefaultColors.primary.red }}>No data found. Please upload a file with data.</div>
            });
            return;
        }

        console.log(`Took ${stats.timeElapsed} to process file and ${DuDateUtilities.TimeSpanToString(processSheetStartTime, new Date())} to process the sheet`);
        this.setState({ fileName: stats.fileName });
        this.props.parsed({ data: readData, fileName: stats.fileName });
    };

    /**
     * @description Dialog to select sheet in case of multiple sheets
     * @memberof DrcFileUpload
     */
    chooseSheetDialog = async () => {
        await this.handleSheet(this.state.file, this.state.sheetSelected, this.state.stats);
        this.closeSheetDialog();
    };

    /**
     * @description Closes choose sheet dialog
     * @memberof DrcFileUpload
     */
    closeSheetDialog = () => {
        this.setState({
            file: null,
            stats: null,
            sheetDialogOpen: false,
            sheetSelected: ''
        });
    };

    /**
     * @description Function to select sheet name when multiple present
     * @param {String} sheetName name of the sheet selected
     * @memberof DrcFileUpload
     */
    handleRadioButtonChange = (sheetName) => {
        this.setState({ sheetSelected: sheetName });
    };

    /**
     * @description Shows loading screen
     * @memberof DrcFileUpload
     */
    showLoadingScreen() {
        this.setState({
            isLoading: true
        });
    }

    /**
     * @description Hides loading screen
     * @memberof DrcFileUpload
     */
    hideLoadingScreen() {
        this.setState({
            isLoading: false
        });
    }
    /**
     * @return {*} Field-area to drag/drop and upload files
     * @memberof DrcFileUpload
     */
    render() {
        const { classes } = this.props;
        this.connectDrop();
        return (
            <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-xs-6">
                    <div
                        ref={this.dropElement}
                        className={classes.dragArea}
                        onDragOver={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <CloudUploadOutlinedIcon fontSize="large" />
                        <div style={{ fontSize: '1.5rem' }}>Drag & Drop file here</div>
                        <div style={{ fontSize: '1.5rem' }}>or</div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-xs-4">
                                <DrcButton
                                    isSecondary
                                    className={classes.uploadBtn}
                                    onClick={() => {
                                        this.inputElement.current.click();
                                    }}
                                >
                                    Select File
                                </DrcButton>
                            </div>
                        </div>
                        <br />
                        {this.state.fileName}
                    </div>
                    <div style={{ display: 'none' }}>{DrcUploadUtilities.FileInput(this.handleFile, this.inputElement, this.statusUpdate, this.props.fileTypeOptions)}</div>
                </div>
                <DrcDialog
                    title="Please choose which sheet to use"
                    open={this.state.sheetDialogOpen}
                    buttons={
                        <div style={{ float: 'right', marginRight: -8 }}>
                            <DrcButton isSecondary onClick={this.closeSheetDialog}>
                                Stop Processing
                            </DrcButton>
                            <DrcButton disabled={this.state.sheetSelected === ''} isPrimary onClick={this.chooseSheetDialog}>
                                Continue
                            </DrcButton>
                        </div>
                    }
                >
                    <div className="row">
                        {this.state.file !== null
                            ? this.state.file.SheetNames.map((sheetName, index) => (
                                  <div className="col-xs-6" key={'sheet' + index}>
                                      <FormControlLabel
                                          name={sheetName}
                                          value={sheetName}
                                          control={<Radio checked={this.state.sheetSelected === sheetName} onChange={() => this.handleRadioButtonChange(sheetName)} />}
                                          label={sheetName}
                                      />
                                  </div>
                              ))
                            : null}
                    </div>
                </DrcDialog>
                <DrcBackdrop isLoading show={this.state.isLoading} />
            </div>
        );
    }
}

export default withStyles(styles)(DrcFileUpload);
