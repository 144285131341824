import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcSelect from './DrcSelect';
import DrcButton from './DrcButton';
// import DrcDatepicker from './DrcDatepicker';
import DrcDateRangePicker from './DrcDateRangePicker';
import DrcInput from './DrcInput';
import DrcBerrySelect from './DrcBerrySelect';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import AddIcon from '@material-ui/icons/Add';

var dateOpen = false;

/**
 *@ignore
 */

var styles = (theme) => ({
    selects: {
        marginTop: -2,
        marginBottom: -5
    }
});



/**
 * @description DrcFilterTag is a styled BackgroundImage
 * @class DrcFilterTag
 * @property {array} filters list of filter
 * @property {function} onRemove method to be triggered when removing
 * @property {string} tagDivStyle style for the tags
 * @property {string} filterDivStyle style for the filter
 * @property {array} children list of children
 * @property {boolean} hideSearchBtn hides the searchButton
 * @example <DrcFilterTag />
 * @extends {React.Component}
 */

class DrcFilterTag extends React.Component {
    /**
     * Creates an instance of DrcFilterTag.
     * @param {*} props
     * @memberof DrcFilterTag
     * 
     */
    constructor(props) {
        super(props);

        this.state = {
            showAddBtn: true,
            inputType: '',
            showSelect: false,
            showText: false,
            name: '',
            startDate: null,
            endDate: null,
            selectedValue: {},
            remoteDataLoaded: false
        };

        this.getLabel = this.getLabel.bind(this);
        this.onAddBtnClick = this.onAddBtnClick.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSelectValueChange = this.onSelectValueChange.bind(this);
        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.handleBerrySelectChange = this.handleBerrySelectChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.filterVariableOptions = this.filterVariableOptions.bind(this);
        this.updateFunction = this.updateFunction.bind(this);
    }

    /**
     * @private 
     * @description function for updating used data. 
     * @memberof DrcFilterTag
     */
    updateFunction(data) {
        this.props.masterData[this.state.name] = data;
        this.setState({ remoteDataLoaded: true });
    }

    /**
     * @private 
     * @description function for filtering selected optionsa
     * @memberof DrcFilterTag
     */
    filterVariableOptions() {
        var filteredFilters = this.props.variableOptions.filter((val) => {
            if (!this.props.selectedFilters[val.value] || !this.props.selectedFilters[val.value].isSet) {
                return true;
            }
            return false;
        });
        return filteredFilters;
    }

    /**
     * @private
     * @description function that handles daterange changes
     * @memberof DrcFilterTag
     */
    handleDateRangeChange(name, startDate, endDate) {
        if (startDate === null || endDate === null) {
            this.props.removeFilter(name);
        } else {
            var convertedStartDate = DuDateUtilities.ToIsoDate(startDate);
            var convertedEndDate = DuDateUtilities.ToIsoDate(endDate);

            this.setState({ startDate, endDate });
            this.props.setFilter(name, {
                label: name,
                value: {
                    StartDate: convertedStartDate,
                    EndDate: convertedEndDate
                },
                index: this.props.index,
                isSet: true
            });
        }
    }
    
    /**
     * @private 
     * @description function called on selection of berry changed
     * @memberof DrcFilterTag
     */
    handleBerrySelectChange(option, actionType) {
        if (actionType.action === 'clear') {
            this.setState({
                showAddBtn: true,
                inputType: '',
                showSelect: false,
                showText: false,
                name: '',
                startDate: null,
                endDate: null,
                selectedValue: null
            });
            this.props.removeFilter('BerryType');
        } else if (option === null) {
            this.setState({
                selectedValue: null
            });
            this.props.setFilter('BerryType', {
                value: '',
                label: '',
                index: this.props.index,
                isSet: true
            });
        } else {
            this.setState({
                selectedValue: option
            });
            this.props.setFilter('BerryType', {
                value: option.value,
                label: option.label,
                index: this.props.index,
                isSet: true
            });
        }
    }

    /**
     * @private
     * @description method to help labels to be populated
     * @memberof DrcFilterTag
     */
    getLabel() {
        var options = this.props.variableOptions.filter((val) => {
            return val.value === this.state.name;
        });

        if (options.length > 0) {
            return options[0].label;
        }

        return '';
    }

    /**
     * @private
     * @description function that is triggerd pressing the add button, hides the add button
     * @memberof DrcFilterTag
     */
    onAddBtnClick(event) {
        this.setState({
            showAddBtn: false,
            inputType: '',
            showSelect: false,
            showText: false,
            name: ''
        });
    }

    /**
     * @ignore
     * @memberof DrcFilterTag
     */
    onSelectChange(option, event) {
        if (!option) {
            console.error('This is bad fix me!');
        }

        if (option.inputType === 'date') {
            //Need to Set the variable name label and show the correct select or date input
            this.setState({
                showAddBtn: false,
                inputType: 'date',
                showSelect: false,
                showText: false,
                name: option.value
            });
        } else if (option.inputType === 'text') {
            this.setState({
                showAddBtn: false,
                inputType: 'text',
                showSelect: false,
                showText: true,
                name: option.value,
                selectedValue: ''
            });
        } else if (option.inputType === 'select') {
            this.setState({
                showAddBtn: false,
                inputType: 'select',
                showSelect: true,
                showText: false,
                name: option.value
            });
        }
        //Set the value to isSet for the right variable, hopefully this makes a new add btn for us
        this.props.setFilter(option.value, { value: '', label: '', index: this.props.index, isSet: true });
    }
    
    /**
     * @private 
     * @description setting selected values in the state
     * @memberof DrcFilterTag
     */
    onSelectValueChange(option, actionType, name) {
        if (actionType.action === 'clear') {
            this.setState({
                showAddBtn: true,
                inputType: '',
                showSelect: false,
                showText: false,
                name: '',
                startDate: null,
                endDate: null,
                selectedValue: null
            });
            this.props.removeFilter(name);
        } else if (option === null) {
            this.setState({
                selectedValue: null
            });
            this.props.setFilter(name, {
                value: '',
                label: '',
                index: this.props.index,
                isSet: true
            });
        } else {
            this.setState({
                selectedValue: option
            });
            this.props.setFilter(name, {
                value: option.value,
                label: option.label,
                index: this.props.index,
                isSet: true
            });
        }
    }

    /**
     * @private
     * @description sets the value after changing it, and stores this in the state
     * @memberof DrcFilterTag
     */
    onInputValueChange(event, name) {
        var text = event.target.value;

        if ((this.state.selectedValue || '').length > 0 && text.length <= 0) {
            this.setState({
                showAddBtn: true,
                inputType: '',
                showSelect: false,
                showText: false,
                name: '',
                startDate: null,
                endDate: null,
                selectedValue: ''
            });
            this.props.removeFilter(name);
        } else {
            this.setState({
                selectedValue: text || ''
            });
            this.props.setFilter(name, {
                value: text,
                label: text,
                index: this.props.index,
                isSet: true
            });
        }
    }

    /**
     * @private
     * @description part of the react component lifecyle
     * @memberof DrcFilterTag
     */
    componentDidMount() {
        if (this.props.variableName !== undefined) {
            this.setState({
                showAddBtn: false,
                inputType: this.props.variableName.inputType,
                showSelect: this.props.inputType === 'select',
                showText: this.props.inputType === 'text',
                name: this.props.variableName
            });
        }
    }

    /**
     * @private
     * @description part of the react component lifecyle
     * @memberof DrcFilterTag
     */
    componentDidUpdate() {
        if (this.props.clearFlag === undefined && this.state.clearFlag === undefined) {
            if (this.props.variableName !== undefined && this.state.inputType === undefined) {
                var inputType;
                this.props.variableOptions.forEach((option) => {
                    if (option.value === this.props.variableName) {
                        inputType = option.inputType;
                        this.dataproviderService = option.dataprovider || null;
                        this.filterFunction = option.filterFunction || null;
                    }
                });

                let startDate = null,
                    endDate = null;
                if (inputType === 'date') {
                    const value = this.props.selectedFilters[this.props.variableName].value;
                    if (value && value.hasOwnProperty('startDate')) {
                        startDate = this.props.selectedFilters[this.props.variableName].value.startDate;
                    }
                    if (value && value.hasOwnProperty('endDate')) {
                        endDate = this.props.selectedFilters[this.props.variableName].value.endDate;
                    }
                }

                this.setState({
                    showAddBtn: false,
                    inputType: inputType,
                    name: this.props.variableName,
                    startDate: startDate,
                    endDate: endDate,
                    selectedValue: inputType === 'text' ? this.props.selectedFilters[this.props.variableName].value : this.props.selectedFilters[this.props.variableName]
                });
            }
        } else if (this.props.clearFlag !== this.state.clearFlag) {
            this.setState({
                showAddBtn: true,
                inputType: '',
                showSelect: false,
                showText: false,
                name: this.props.variableName,
                startDate: null,
                endDate: null,
                clearFlag: this.props.clearFlag
            });
        }
    }

    /**
     * @description render method for the DrcCookieMessage,  part of the react component lifecyle
     * @return {*} the view for this component
     * @memberof DrcFilterTag
     */
    render() {
        const { children, filters, onRemove, tagDivStyle, filterDivStyle, hideSearchBtn, classes } = this.props;
        const filterClass = this.state.inputType === 'date' ? 'col-lg-4' : 'col-lg-3';

        return (
            <div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 ${filterClass}`}>
                {this.state.showAddBtn ? (
                    <DrcButton
                        isSecondary
                        onClick={this.onAddBtnClick}
                        style={{
                            height: '35px',
                            padding: '6px',
                            margin: '5px 8px 5px 0'
                        }}
                    >
                        Add&nbsp;Filter&nbsp;
                        <AddIcon />
                    </DrcButton>
                ) : null}
                {this.state.inputType === '' && !this.state.showAddBtn ? (
                    <div className={classes.selects}>
                        <DrcSelect
                            name={this.props.index}
                            value={this.props.selectedVariable}
                            onChange={this.onSelectChange}
                            options={this.filterVariableOptions()}
                            inputLabelProps={{ isClearable: true }}
                            label="-"
                            disabled={true}
                            isClearable={true}
                        />
                    </div>
                ) : null}
                {this.state.inputType === 'select' ? (
                    <div className={classes.selects}>
                        <DrcSelect
                            label={this.getLabel()}
                            options={
                                typeof this.dataproviderService === 'function' && !this.state.remoteDataLoaded
                                    ? this.dataproviderService.call(null, this.updateFunction)
                                    : typeof this.filterFunction === 'function' && !this.state.remoteDataLoaded
                                    ? this.filterFunction.call(null, this.updateFunction)
                                    : this.props.masterData[this.state.name]
                            }
                            value={this.state.selectedValue.value === '' ? null : this.state.selectedValue}
                            index={this.props.index}
                            isClearable={true}
                            onChange={(option, type) => this.onSelectValueChange(option, type, this.state.name)}
                        />
                    </div>
                ) : null}
                {this.state.inputType === 'text' ? (
                    <div className={classes.selects}>
                        <DrcInput
                            label={this.getLabel()}
                            value={this.state.selectedValue}
                            index={this.props.index}
                            onChange={(event) => this.onInputValueChange(event, this.state.name)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                ) : null}
                {this.state.inputType === 'date' ? (
                    <DrcDateRangePicker
                        label={this.getLabel()}
                        name={this.state.name}
                        index={this.props.index}
                        onChange={(startDate, endDate) => this.handleDateRangeChange(this.state.name, startDate, endDate)}
                        onClose={(event) => this.props.removeFilter(this.state.name)}
                        months={2}
                        direction="horizontal"
                    />
                ) : null}
                {this.state.inputType === 'berrySelect' ? (
                    <div className={classes.selects}>
                        <DrcBerrySelect
                            name={this.state.name}
                            onChange={(option, type) => this.handleBerrySelectChange(option, type)}
                            value={this.state.selectedValue.value === '' ? null : this.state.selectedValue}
                            index={this.props.index}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcFilterTag);
