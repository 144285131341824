import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcInput from './DrcInput';
import DrcSelect from './DrcSelect';
import DrcDatePicker from './DrcDatepicker';
import DrcDateTimePicker from './DrcDateTimePicker';
import DrcSwitch from './DrcSwitch';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import Chip from '@material-ui/core/Chip';

/**
 *@ignore
 */
const styles = (theme) => ({
    chips: {
        border: 'solid 1px grey',
        padding: 10,
        borderRadius: 4,
        marginBottom: 10
    },
    chip: {
        marginLeft: 10,
        margin: 1
    },
    column: {
        width: '50%',
        marginLeft: 10
    }
});

/**
 * @description DrcPropertyDetails is to render field based on metadata info provided in the Driscolls format and is customizable
 * @version 1.0
 * @class DrcPropertyDetails
 * @example
 * let propOptions = [{label :"option1", value: "value1"}, {label: "option2", value: "value2"}];
 * let metadata = {settingName: 'useDarkMode', datatype: 'boolean', isList: false, userPermissions: 'read', managerPermissions: 'write'};
 * <DrcPropertyDetails
        metadata={metadata}
        propOptions={propOptions}
        settingType={'global'}
        value={true}
        onChange={this.onChange}
        readOnly={false}
    />
 * @property {array} propOptions options
 * @property {string} settingType value ex - global/app
 * @property {string} value value
 * @property {json} metadata metadata of field
 * @property {boolean} readOnly disable component
 * @extends {React.Component} */
class DrcPropertyDetails extends Component {
    state = {
        propOptions: []
    };

    /**
     *@ignore
     */
    componentDidMount() {}

    /**
     *
     * @description method to make option for the value
     * @param {*} value
     * @memberof DrcPropertyDetails
     */
    makeOption = (value) => {
        return { label: value, value: value };
    };

    /**
     *
     * @description method to handle delete specific to date
     * @param {*} value
     * @param {*} settingName
     * @param {*} type
     * @param {*} datatype
     * @param {*} isList
     * @memberof DrcPropertyDetails
     */
    handleDateDelete = (value, settingName, type, datatype, isList) => {
        this.props.onChange(value, settingName, type, datatype, isList, 'delete');
    };

    /**
     *
     * @description method to handle delete
     * @param {*} value
     * @param {*} settingName
     * @param {*} type
     * @param {*} datatype
     * @param {*} isList
     * @memberof DrcPropertyDetails
     */
    handleDelete = (value, settingName, type, datatype, isList) => {
        this.props.onChange(value, settingName, type, datatype, isList, 'delete');
    };

    /**
     *
     *
     * @return {*}
     * @memberof DrcPropertyDetails
     */
    render() {
        const { classes, readOnly, value, settingType, propOptions } = this.props;
        const { settingName, datatype, isList } = this.props.metadata;
        return (
            <div className="row">
                <div className={classes.column}>
                    {datatype ? (
                        (datatype === 'text' || datatype === 'number') && !isList ? (
                            <DrcInput
                                label={settingName}
                                value={value}
                                disabled={readOnly}
                                type={datatype === 'number' ? 'number' : 'text'}
                                onChange={(e) => this.props.onChange(e.target.value, settingName, settingType)}
                            />
                        ) : datatype === 'boolean' && !isList ? (
                            <DrcSwitch
                                isRequired={true}
                                label={settingName}
                                disabled={readOnly}
                                value={value}
                                onChange={(e) => this.props.onChange(e.target.checked, settingName, settingType)}
                            >
                                {settingName}
                            </DrcSwitch>
                        ) : datatype === 'date' && !isList ? (
                            <DrcDatePicker label={settingName} value={new Date(value)} disabled={readOnly} onChange={(e) => this.props.onChange(e, settingName, settingType)} />
                        ) : datatype === 'datetime' && !isList ? (
                            <DrcDateTimePicker label={settingName} value={new Date(value)} disabled={readOnly} onChange={(e) => this.props.onChange(e, settingName, settingType)} />
                        ) : (datatype === 'date' || datatype === 'datetime') && isList ? (
                            <div className={classes.chips}>
                                {readOnly ? (
                                    <p>{settingName}</p>
                                ) : (
                                    <div className="col-sm-5">
                                        {datatype === 'date' ? (
                                            <DrcDatePicker label={settingName} disabled={readOnly} onChange={(e) => this.props.onChange(e, settingName, settingType, isList)} />
                                        ) : (
                                            <DrcDateTimePicker label={settingName} disabled={readOnly} onChange={(e) => this.props.onChange(e, settingName, settingType, isList)} />
                                        )}
                                    </div>
                                )}
                                <div>
                                    {value
                                        ? value.map((value) => {
                                              return readOnly ? (
                                                  <Chip
                                                      variant="outlined"
                                                      label={DuDateUtilities.FormatDateFromIso(new Date(value).toISOString())}
                                                      className={classes.chip}
                                                      clickable
                                                  />
                                              ) : (
                                                  <Chip
                                                      variant="outlined"
                                                      label={DuDateUtilities.FormatDateFromIso(new Date(value).toISOString())}
                                                      className={classes.chip}
                                                      clickable
                                                      onDelete={() => this.handleDateDelete(value, settingName, settingType, isList, 'delete')}
                                                  />
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                        ) : isList ? (
                            <div className={classes.chips}>
                                {readOnly ? (
                                    <p>{settingName}</p>
                                ) : (
                                    <div className="col-sm-8">
                                        <DrcSelect
                                            label={settingName}
                                            options={propOptions}
                                            isOptionDisabled={value ? (option) => value.includes(option.value) : false}
                                            isDisabled={readOnly}
                                            onChange={(e) => this.props.onChange(e.value, settingName, settingType, isList)}
                                        />
                                    </div>
                                )}
                                <div>
                                    {value
                                        ? value.map((value) => {
                                              return readOnly ? (
                                                  <Chip variant="outlined" className={classes.chip} label={value.toString()} clickable />
                                              ) : (
                                                  <Chip
                                                      variant="outlined"
                                                      className={classes.chip}
                                                      label={value.toString()}
                                                      clickable
                                                      onDelete={() => this.handleDelete(value, settingName, settingType, isList, 'delete')}
                                                  />
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                        ) : null
                    ) : null}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(DrcPropertyDetails);
